import { toast, ToastContent, ToastOptions } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css'; // <- add line 

const defaultToastOptions: ToastOptions = {
  position: toast.POSITION.BOTTOM_RIGHT,
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  draggable: true,
};

export const helperToast = {
  success: (content: ToastContent, opts?: ToastOptions) => {
    toast.dismiss();
    toast.success(content, {... defaultToastOptions , ...opts});
  },
  error: (content: ToastContent, opts?: ToastOptions) => {
    toast.dismiss();
    toast.error(content, {... defaultToastOptions , ...opts});
  },  
  info: (content: ToastContent, opts?: ToastOptions) => {
    console.log('info',opts);
    toast.dismiss();
    toast.info(content, {... defaultToastOptions , ...opts});
  },
};
