

export const EditableInput = ({ originalObject, editedObject, field, onChange, isEditable, className }) => {
    return <>{isEditable ? (
      <input
        type="text"
        value={editedObject[field]}
        onChange={(e) => onChange(field, e.target.value)}
        readOnly={!isEditable}
        style={{ width: '100%', padding: '0.2em', backgroundColor: '#CCC' }}
        className={className?className:''}
      />
    ) : (
    <div style={{ width: '91%' }}>{originalObject[field]}</div>
    )}
    </>
  }

  export const EditableDropdown = ({ originalObject, editedObject, field, onChange, isEditable, options }) => {
    return <>
    {isEditable ? (
      <select id={field} value={editedObject[field]} onChange={onChange}>
      {options.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </select>
    ) : (
    <div style={{ width: '95%' }}>{originalObject[field]}</div>
    )}
    </>

}

export const FilterableSortableColumnHeader = ({ colTitle, field, handleSort, value, handleFilterChange }) => {
  return (
    <>
    <span className="sortable-element" onClick={() => handleSort(field)}>{colTitle}</span>
    <input type="text" value={value} onChange={(e) => handleFilterChange(field, e)} placeholder="Filter"
            style={{border: 'none', borderBottom: '1px solid #ccc', padding: '2px', fontSize: '11px', width: '75px'}}
    />
  </>
  );
}