import { ethers } from "ethers";
import { Web3Provider } from "@ethersproject/providers";

export const contractFetcher =
  <T>(library: Web3Provider, contractInfo: any, additionalArgs: any[]) =>
  (...args: any): Promise<T> => {
    // eslint-disable-next-line
    const [id, chainId, arg0, arg1, ...params] = args[0];
    const provider = library.getSigner(); 

    const method = ethers.isAddress(arg0) ? arg1 : arg0;

    const contractCall = getContractCall({
      provider,
      contractInfo,
      arg0,
      arg1,
      method,
      params,
      additionalArgs,
    });

    return new Promise(async (resolve, reject) => {
      contractCall
        .then((result) => {
          //console.log("fetcher result", id, contractInfo.contractName, method, result);
          resolve(result);
        })
        .catch((e) => {
          //console.error("fetcher error", id, contractInfo.contractName, method, e);
          reject(e);
        });

      setTimeout(() => {
        reject("contractCall timeout");
      }, 2000);
    });
  };

function getContractCall({ provider, contractInfo, arg0, arg1, method, params, additionalArgs }) {
  if (ethers.isAddress(arg0)) {
    const address = arg0;
    const contract = new ethers.Contract(address, contractInfo.abi, provider);

    if (additionalArgs) {
      return contract[method](...params.concat(additionalArgs));
    }
    return contract[method](...params);
  }

  if (!provider) {
    return;
  }

  return provider[method](arg1, ...params);
}
