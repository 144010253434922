import mockDeals from "../mocks/with-results.json";
import mockTiers from "../mocks/tiers-with-results.json";
import mockDealParameters from "../mocks/deal-params-with-results.json";
import mockRoles from "../mocks/roles-with-results.json";
import mockInvestors from "../mocks/investors-with-results.json";

const API_KEY = "4287ad07";
const MODE = process.env.REACT_APP_MODE || "MOCK";

const PROD = "PROD";
const DEV = "DEV";
const LOCAL = "LOCAL";

const BASE_URLS = {
  PROD: "https://presales.clubcerberus.org",
  DEV: "https://dev.presales.clubcerberus.org",
  LOCAL: "http://127.0.0.1:15001",
};

const URLS = {
  PROD: {
    deals: `${BASE_URLS[PROD]}/search`,
    tiers: `${BASE_URLS[PROD]}/tiers`,
    dealParameters: `${BASE_URLS[PROD]}/dealparameters`,
    roles: `${BASE_URLS[PROD]}/roles`,
    investors: `${BASE_URLS[PROD]}/investors`,
    updateInvestment: `${BASE_URLS[PROD]}/updateinvestment`,
    updateInvestorDetails: `${BASE_URLS[PROD]}/updateinvestordetails`,
    globalData: `${BASE_URLS[PROD]}/globaldata`,
    img: `${BASE_URLS[PROD]}/img`,
    uploadImg: `${BASE_URLS[PROD]}/uploadImg`,
    ckey: `${BASE_URLS[PROD]}/ckey`,
  },
  DEV: {
    deals: `${BASE_URLS[DEV]}/search`,
    tiers: `${BASE_URLS[DEV]}/tiers`,
    dealParameters: `${BASE_URLS[DEV]}/dealparameters`,
    roles: `${BASE_URLS[DEV]}/roles`,
    investors: `${BASE_URLS[DEV]}/investors`,
    updateInvestment: `${BASE_URLS[DEV]}/updateinvestment`,
    updateInvestorDetails: `${BASE_URLS[DEV]}/updateinvestordetails`,
    globalData: `${BASE_URLS[DEV]}/globaldata`,
    img: `${BASE_URLS[DEV]}/img`,
    uploadImg: `${BASE_URLS[DEV]}/uploadImg`,
    ckey: `${BASE_URLS[DEV]}/ckey`,
  },
  LOCAL: {
    deals: `${BASE_URLS[LOCAL]}/search`,
    tiers: `${BASE_URLS[LOCAL]}/tiers`,
    dealParameters: `${BASE_URLS[LOCAL]}/dealparameters`,
    roles: `${BASE_URLS[LOCAL]}/roles`,
    investors: `${BASE_URLS[LOCAL]}/investors`,
    updateInvestment: `${BASE_URLS[LOCAL]}/updateinvestment`,
    updateInvestorDetails: `${BASE_URLS[LOCAL]}/updateinvestordetails`,
    globalData: `${BASE_URLS[LOCAL]}/globaldata`,
    img: `${BASE_URLS[LOCAL]}/img`,
    uploadImg: `${BASE_URLS[LOCAL]}/uploadImg`,
    ckey: `${BASE_URLS[LOCAL]}/ckey`,
  },
};

const MOCKS = {
  deals: mockDeals,
  tiers: mockTiers,
  dealparameters: mockDealParameters,
  roles: mockRoles,
  investors: mockInvestors,
};

const getURL = (kind, mode, search) => {
  switch (kind) {
    case "deals":
      return `${URLS[mode].deals}?what=${search}`;
    case "tiers":
      return `${URLS[mode].tiers}?wallet=${search}`;
    case "dealParameters":
      return search
        ? `${URLS[mode].dealParameters}?deal=${search}`
        : `${URLS[mode].dealParameters}`;
    case "roles":
      return `${URLS[mode].roles}`;
    case "investors":
      return `${URLS[mode].investors}`;
    case "updateInvestment":
      return `${URLS[mode].updateInvestment}`;
    case "updateInvestorDetails":
      return `${URLS[mode].updateInvestorDetails}`;
    case "globalData":
      return `${URLS[mode].globalData}`;
    case "img":
      return `${URLS[mode].img}`;
    case "uploadImg":
      return `${URLS[mode].uploadImg}`;
    case "ckey":
      return `${URLS[mode].ckey}?key=${search}`;
    default:
      throw new Error(`Unknown kind ${kind}`);
  }
};

const getMock = (kind) => {
  return MOCKS[kind];
};

export const searchData = async ({ kind, search }) => {
  if (search === "") return null;
  try {
    let json;
    if (MODE === "MOCK") {
      json = getMock(kind);
    } else {
      const url = getURL(kind, MODE, search);
      const response = await fetch(url);
      json = await response.json();
    }
    const result = json.Search;
    return result;
  } catch (e) {
    throw new Error(`Error searching ${kind}`);
  }
};

/*
  const fetchBlockNumber = (...args) => {
    const [id, chainId] = args;
    //const method = chainId === ZKSYNC_TESTNET ? "zks_L1BatchNumber" : "eth_blockNumber";
    const method = "eth_blockNumber";
    const url = getRpcUrl(chainId);

    let headers = { "Content-Type": "application/json" };
    let body = { jsonrpc: "2.0", id: 1, method: method, params: [] };
    return fetch(url, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((res) => parseInt(res.result));
  };
*/
/*
{
  "dealName": "zkSync",
  "wallet": "0xfe0bdd25937ae9dd8b3f9511235ea9940a02d72e",
  "amount": "666",
  "fee": "20",
  "tokenPrice": "0.2",
  "mode": "substract"
}
*/

export const updateInvestment = async ({
  dealName,
  wallet,
  telegramId,
  amount,
  fee,
  tokenPrice,
  mode = "add",
  txHash,
  notes,
  isDelegated,
  onError,
  onSuccess,
}) => {
  if (dealName === "") return null;

  const url = getURL("updateInvestment", MODE);
  let body = {
    dealName,
    wallet,
    amount,
    ...(telegramId !== undefined && { telegramId }),
    ...(fee !== undefined && { fee }),
    ...(tokenPrice !== undefined && { tokenPrice }),
    ...(mode !== undefined && { mode }),
    ...(txHash !== undefined && { txHash }),
    ...(notes !== undefined && { notes }),
    ...(isDelegated !== undefined && { isDelegated }),
  };

  let headers = { "Content-Type": "application/json" };
  return fetch(url, {
    method: "POST",
    headers: headers,
    body: JSON.stringify(body),
  })
    .then((response) => {
      if (!response.ok) onError();

      onSuccess();
      return response;
    })
    .catch((error) => {
      onError();
      console.error(error);
    });
};
export const removeInvestorDetails = async ({ wallet, onError, onSuccess }) => {
  console.log("removeInvestorDetails", wallet);
  if (!wallet) return null;

  const url = getURL("updateInvestorDetails", MODE);
  let body = {
    wallet,
  };
  let headers = { "Content-Type": "application/json" };
  return fetch(url, {
    method: "DELETE",
    headers: headers,
    body: JSON.stringify(body),
  })
    .then((response) => {
      if (!response.ok) onError();

      onSuccess();
      return response;
    })
    .catch((error) => {
      onError();
      console.error(error);
    });
};

export const updateInvestorDetails = async ({
  wallet,
  telegramId,
  discordId,
  email,
  role,
  tier,
  tierFee,
  onError,
  onSuccess,
}) => {
  if (!wallet) return null;

  const url = getURL("updateInvestorDetails", MODE);
  let body = {
    wallet,
    ...(telegramId !== undefined && { telegramId }),
    ...(discordId !== undefined && { discordId }),
    ...(email !== undefined && { email }),
    ...(role !== undefined && { role }),
    ...(tier !== undefined && { tier }),
    ...(tierFee !== undefined && { tierFee }),
  };
  let headers = { "Content-Type": "application/json" };
  return fetch(url, {
    method: "POST",
    headers: headers,
    body: JSON.stringify(body),
  })
    .then((response) => {
      if (!response.ok) onError();

      onSuccess();
      return response;
    })
    .catch((error) => {
      onError();
      console.error(error);
    });
};

/*
    dealName = request.json.get('deal', NOT_SET)    
    if dealName == NOT_SET:
        return jsonify({"error": "dealName 'deal' is required"}), 400
    
    newDealName = request.json.get('newName', NOT_SET)
    raisingWallet = request.json.get('raisingWallet',NOT_SET)
    generalFee = DealsUtils.toFloat(request.json.get('generalFee',NOT_SET))
    vcFee = DealsUtils.toFloat(request.json.get('vcFee',NOT_SET))
    vc = request.json.get('vc',NOT_SET)
    tge = DealsUtils.toFloat(request.json.get('tge',NOT_SET))
    tokenPrice = DealsUtils.toFloat(request.json.get('tokenPrice',NOT_SET))
    round = request.json.get('round',NOT_SET)
    cliff = DealsUtils.toFloat(request.json.get('cliff',NOT_SET))
    vestingData = DealsUtils.toFloat(request.json.get('vestingData',NOT_SET))
    vesting = DealsUtils.toFloat(request.json.get('vesting',NOT_SET))
    detailsUrl = request.json.get('detailsUrl',NOT_SET)
    date = request.json.get('date',NOT_SET)
    status = request.json.get('status',NOT_SET)
    substatus = request.json.get('substatus',NOT_SET)
    */

export const updateDealParameters = async ({
  deal,
  newName,
  raisingWallet,
  generalFee,
  vcFee,
  vc,
  tge,
  tgeData,
  tokenPrice,
  round,
  cliff,
  vestingData,
  vesting,
  detailsUrl,
  date,
  status,
  substatus,
  onError,
  onSuccess,
}) => {
  if (!deal) return null;

  const url = getURL("dealParameters", MODE);
  let body = {
    deal,
    ...(newName !== undefined && { newName }),
    ...(raisingWallet !== undefined && { raisingWallet }),
    ...(generalFee !== undefined && { generalFee }),
    ...(vcFee !== undefined && { vcFee }),
    ...(vc !== undefined && { vc }),
    ...(tge !== undefined && { tge }),
    ...(tgeData !== undefined && { tgeData }),
    ...(tokenPrice !== undefined && { tokenPrice }),
    ...(round !== undefined && { round }),
    ...(cliff !== undefined && { cliff }),
    ...(vestingData !== undefined && { vestingData }),
    ...(vesting !== undefined && { vesting }),
    ...(detailsUrl !== undefined && { detailsUrl }),
    ...(date !== undefined && { date }),
    ...(status !== undefined && { status }),
    ...(substatus !== undefined && { substatus }),
  };

  let headers = { "Content-Type": "application/json" };
  return fetch(url, {
    method: "POST",
    headers: headers,
    body: JSON.stringify(body),
  })
    .then((response) => {
      if (!response.ok) onError();

      onSuccess();
      return response;
    })
    .catch((error) => {
      onError();
      console.error(error);
    });
};

export const getImageUrl = (name) => {
  return `${URLS[MODE].img}/${name}.png`;
};

export const uploadImageToServer = async ({ file, onSuccess, onError }) => {
  const formData = new FormData();
  formData.append("image", file);
  return fetch(`${URLS[MODE].uploadImg}`, {
    method: "POST",
    body: formData,
  })
    .then((response) => {
      if (!response.ok) onError();
      onSuccess();
      return response;
    })
    .catch((error) => {
      onError();
      console.error(error);
    });
};
export const uploadImageToServer2 = async ({ file, onSuccess, onError }) => {
  if (!file) return;
  try {
    const formData = new FormData();
    formData.append("image", file);
    const response = await fetch(`${URLS[MODE].uploadImg}`, {
      method: "POST",
      body: formData,
    });
    const json = await response.json();
    const result = json.url;
    return result;
  } catch (e) {
    throw new Error(`Error uploading image`);
  }
};
