// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* components/Carousel.css */

.carousel-container {
    overflow: hidden;
    width: 90%;
    margin-top: 20px; /* Adjust margin as needed */
  }
  
  .carousel-wrapper {
    display: flex;
    animation: ticker 55s linear infinite; /* Adjust the animation duration as needed */
  }
  
  .carousel-wrapper img {
    width: 50px; /* Adjust the width as needed */
    height: 50px; /* Adjust the height as needed */
    margin-right: 10px; /* Adjust the margin/padding between images */
    object-fit: cover; /* Maintain aspect ratio while filling the container */
  }
  
  @keyframes ticker {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(-100%);
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Carousel.css"],"names":[],"mappings":"AAAA,4BAA4B;;AAE5B;IACI,gBAAgB;IAChB,UAAU;IACV,gBAAgB,EAAE,4BAA4B;EAChD;;EAEA;IACE,aAAa;IACb,qCAAqC,EAAE,4CAA4C;EACrF;;EAEA;IACE,WAAW,EAAE,+BAA+B;IAC5C,YAAY,EAAE,gCAAgC;IAC9C,kBAAkB,EAAE,6CAA6C;IACjE,iBAAiB,EAAE,sDAAsD;EAC3E;;EAEA;IACE;MACE,2BAA2B;IAC7B;IACA;MACE,4BAA4B;IAC9B;EACF","sourcesContent":["/* components/Carousel.css */\n\n.carousel-container {\n    overflow: hidden;\n    width: 90%;\n    margin-top: 20px; /* Adjust margin as needed */\n  }\n  \n  .carousel-wrapper {\n    display: flex;\n    animation: ticker 55s linear infinite; /* Adjust the animation duration as needed */\n  }\n  \n  .carousel-wrapper img {\n    width: 50px; /* Adjust the width as needed */\n    height: 50px; /* Adjust the height as needed */\n    margin-right: 10px; /* Adjust the margin/padding between images */\n    object-fit: cover; /* Maintain aspect ratio while filling the container */\n  }\n  \n  @keyframes ticker {\n    from {\n      transform: translateX(100%);\n    }\n    to {\n      transform: translateX(-100%);\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
