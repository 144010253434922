// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sticky-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 90%;
  background-color: white;
  z-index: 100;
  display: none;
}

.sticky-header.visible {
  display: block;
}

.dealParameters {
  width: auto;
  border-collapse: collapse;
  margin-top: 20px;
  width: 100%;
  table-layout: auto !important ; /* Ensures columns have a fixed layout */
  color: var(--text-muted);
}

.dealParameter {
  background-color: rgb(251, 187, 175);
  border-radius: 8px;
}

.dealParameter td {
  padding: 10px;
  text-align: left;
  width: auto !important;
  border-radius: 8px;
}

.dealParameters th {
  background-color:rgb(249, 131, 108);
  border-radius: 8px;
  color: white;
  padding: 10px;
  text-align: left;
  width: auto !important;
}

.dealParameter img {
  border-radius: 8px;
  margin-top: 8px;
  width: 32px;
  height: 32px;
  padding-bottom: 4px;
}
`, "",{"version":3,"sources":["webpack://./src/components/DealParameters.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,UAAU;EACV,uBAAuB;EACvB,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,yBAAyB;EACzB,gBAAgB;EAChB,WAAW;EACX,8BAA8B,EAAE,wCAAwC;EACxE,wBAAwB;AAC1B;;AAEA;EACE,oCAAoC;EACpC,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,gBAAgB;EAChB,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,mCAAmC;EACnC,kBAAkB;EAClB,YAAY;EACZ,aAAa;EACb,gBAAgB;EAChB,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,WAAW;EACX,YAAY;EACZ,mBAAmB;AACrB","sourcesContent":[".sticky-header {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 90%;\n  background-color: white;\n  z-index: 100;\n  display: none;\n}\n\n.sticky-header.visible {\n  display: block;\n}\n\n.dealParameters {\n  width: auto;\n  border-collapse: collapse;\n  margin-top: 20px;\n  width: 100%;\n  table-layout: auto !important ; /* Ensures columns have a fixed layout */\n  color: var(--text-muted);\n}\n\n.dealParameter {\n  background-color: rgb(251, 187, 175);\n  border-radius: 8px;\n}\n\n.dealParameter td {\n  padding: 10px;\n  text-align: left;\n  width: auto !important;\n  border-radius: 8px;\n}\n\n.dealParameters th {\n  background-color:rgb(249, 131, 108);\n  border-radius: 8px;\n  color: white;\n  padding: 10px;\n  text-align: left;\n  width: auto !important;\n}\n\n.dealParameter img {\n  border-radius: 8px;\n  margin-top: 8px;\n  width: 32px;\n  height: 32px;\n  padding-bottom: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
