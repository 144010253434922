// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  padding: 7px;
  
  font-size: small;
}

.element-with-tooltip:hover + .tooltip {
  display: block;
}
/* Add horizontal padding to wallet icons */
.disconnect-icon {
  width: 16px;
  height: 16px;
  padding: 0 5px; /* 0 top/bottom padding and 5px left/right padding */
}`, "",{"version":3,"sources":["webpack://./src/components/ConnectWallet.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,qBAAqB;AACvB;;AAEA;EACE,aAAa;EACb,kBAAkB;EAClB,SAAS;EACT,OAAO;EACP,UAAU;EACV,yBAAyB;EACzB,sBAAsB;EACtB,YAAY;;EAEZ,gBAAgB;AAClB;;AAEA;EACE,cAAc;AAChB;AACA,2CAA2C;AAC3C;EACE,WAAW;EACX,YAAY;EACZ,cAAc,EAAE,oDAAoD;AACtE","sourcesContent":[".tooltip-container {\n  position: relative;\n  display: inline-block;\n}\n\n.tooltip {\n  display: none;\n  position: absolute;\n  top: 100%;\n  left: 0;\n  z-index: 1;\n  background-color: #f9f9f9;\n  border: 1px solid #ccc;\n  padding: 7px;\n  \n  font-size: small;\n}\n\n.element-with-tooltip:hover + .tooltip {\n  display: block;\n}\n/* Add horizontal padding to wallet icons */\n.disconnect-icon {\n  width: 16px;\n  height: 16px;\n  padding: 0 5px; /* 0 top/bottom padding and 5px left/right padding */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
