import React, { useState, useEffect } from 'react';
import './Carousel.css'; // Create a separate CSS file for Carousel styling
import DealModal from './DealModal';
import { getImageUrl } from '../services/backend.js'

const Carousel = ({ deals }) => {
  const [currentDealIndex, setCurrentDealIndex] = useState(0);
  const [selectedDeal, setSelectedDeal] = useState(null);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDealIndex((prevIndex) => (prevIndex + 1) % deals.length);
    }, 100); // Adjust the interval as needed

    return () => clearInterval(intervalId);
  }, [deals.length]);


  const handleDealClick = (deal) => {
    setSelectedDeal(deal);
  };

  const handleCloseModal = () => {
    setSelectedDeal(null);
  };

  return (
    <div className="carousel-container">
      <div className="carousel-wrapper">
        {/* Duplicate the deals for smooth looping effect */}

        {deals.map((deal, index) => (
            
        <div key={index} onClick={() => handleDealClick(deal)}>
          <img  src={getImageUrl(deal.project)} alt={`Carousel Deal ${index + 1}`}></img>
          </div>
        ))}
      </div>
      {selectedDeal && (
        <DealModal deal={selectedDeal} onClose={handleCloseModal} />
      )}

    </div>
  );
};

export default Carousel;