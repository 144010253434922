import USDT_ABI from "../abis/USDT.json";

export const OPBNB = 5611;
export const BNB = 56;

export const chainsInfo = {
  [OPBNB]: {
    name: "OPBNB",
    chainId: OPBNB,
    shortName: "opbnb",
    chain: "BNB",
    network: "testnet",
    networkId: OPBNB,
    nativeCurrency: {
      name: "BNB",
      symbol: "BNB",
      decimals: 18,
    },
    rpc: ["https://bsc-dataseed1.binance.org"],
    faucets: [],
    explorer: "https://opbnb-testnet.bscscan.com",
    infoURL: "https://www.binance.org",
  },
  [BNB]: {
    name: "BNB",
    chainId: BNB,
    shortName: "bnb",
    chain: "BNB",
    network: "mainnet",
    networkId: BNB,
    nativeCurrency: {
      name: "BNB",
      symbol: "BNB",
      decimals: 18,
    },
    rpc: ["https://bsc-dataseed1.binance.org"],
    faucets: [],
    explorer: "https://bscscan.com",
    infoURL: "https://www.binance.org",
  },
};

export const SUPPORTED_CHAINS = [OPBNB, BNB];
export const DEV_CHAINS = [OPBNB];
export const PROD_CHAINS = [BNB];

const BNB_BUSD_ADDRESS = "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56";
const BNB_USDT_ADDRESS = "0x55d398326f99059fF775485246999027B3197955";

const OPBNB_USDT_ADDRESS = "0x7a7eC46932BFc6f5B105A5a82161930FccE83BFB";

const USDT_DECMIALS = 18;
const BUSD_DECMIALS = 18;

export const BUSD = "BUSD";
export const USDT = "USDT";

export const tokensInfo = {
  [OPBNB]: {
    [USDT]: {
      address: OPBNB_USDT_ADDRESS,
      abi: USDT_ABI,
      decimals: USDT_DECMIALS,
      name: "USDT",
    },
  },
  [BNB]: {
    [BUSD]: {
      address: BNB_BUSD_ADDRESS,
      abi: USDT_ABI,
      decimals: BUSD_DECMIALS,
      name: "BUSD",
    },
    [USDT]: {
      address: BNB_USDT_ADDRESS,
      abi: USDT_ABI,
      decimals: USDT_DECMIALS,
      name: "USDT",
    },
  },
};

export const getTokenInfo = ({ chainId, tokenName }) => {
  try {
    return tokensInfo[chainId][tokenName];
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const getTxUrl = ({ chainId, txHash }) => {
  const chainInfo = chainsInfo[chainId];
  return `${chainInfo.explorer}/tx/${txHash}`;
};
