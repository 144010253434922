import { useRef, useState, useMemo, useCallback, useEffect } from "react";
import { searchData } from "../services/backend.js";

export function useInvestors() {
  const [investors, setInvestors] = useState([]);
  const [investorsList, setInvestorsList] = useState([]);
  const [loading, setLoading] = useState(false);

  const getInvestors = async () => {
    try {
      setLoading(true);
      const newInvestors = await searchData({ kind: "investors" });
      setInvestors(newInvestors);
    } catch (e) {
      console.error(e.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getInvestors();
  }, []);

  const getInvestor = ({ account }) => {
    return investors[account];
  };

  useEffect(() => {
    let _investorsList = [];
    Object.keys(investors).forEach((account) => {
      _investorsList.push({ account: account, ...investors[account] });
    });
    setInvestorsList(_investorsList);
  }, [investors]);

  return { investors, investorsList, getInvestor, getInvestors };
}
