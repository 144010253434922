// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* components/InvestmentForm.css */

.investment-form {
    display: flex;
    flex-direction: column;
  }
  
  .balance-info {
    display: flex;
    justify-content: flex-end; /* Align to the right */
    margin-right: 10px; /* Add some margin between balance info and input/button */
  }
  
  .small-font {
    font-size: 12px;
  }
  
  .input-container {
    display: flex;
    justify-content: space-between; /* Align input and button to the right */
    align-items: center; /* Center vertically */
    margin-top: 10px; /* Add some margin between balance info and input/button */
  }
  
  .input-container input {
    width: 100%;
    text-align: center;
  }
  
  .input-container button {
    margin-left: 10px; /* Add some spacing between input and button */
  }
  `, "",{"version":3,"sources":["webpack://./src/components/InvestmentForm.css"],"names":[],"mappings":"AAAA,kCAAkC;;AAElC;IACI,aAAa;IACb,sBAAsB;EACxB;;EAEA;IACE,aAAa;IACb,yBAAyB,EAAE,uBAAuB;IAClD,kBAAkB,EAAE,0DAA0D;EAChF;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,aAAa;IACb,8BAA8B,EAAE,wCAAwC;IACxE,mBAAmB,EAAE,sBAAsB;IAC3C,gBAAgB,EAAE,0DAA0D;EAC9E;;EAEA;IACE,WAAW;IACX,kBAAkB;EACpB;;EAEA;IACE,iBAAiB,EAAE,8CAA8C;EACnE","sourcesContent":["/* components/InvestmentForm.css */\n\n.investment-form {\n    display: flex;\n    flex-direction: column;\n  }\n  \n  .balance-info {\n    display: flex;\n    justify-content: flex-end; /* Align to the right */\n    margin-right: 10px; /* Add some margin between balance info and input/button */\n  }\n  \n  .small-font {\n    font-size: 12px;\n  }\n  \n  .input-container {\n    display: flex;\n    justify-content: space-between; /* Align input and button to the right */\n    align-items: center; /* Center vertically */\n    margin-top: 10px; /* Add some margin between balance info and input/button */\n  }\n  \n  .input-container input {\n    width: 100%;\n    text-align: center;\n  }\n  \n  .input-container button {\n    margin-left: 10px; /* Add some spacing between input and button */\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
