
import React, { useEffect, useContext } from 'react';
import ReactDOM from 'react-dom';
import { useDealsInfo } from '../hooks/useDealsInfo';

import { useDealParameters } from '../hooks/useDealParameters.js'

import InvestmentForm from './InvestmentForm';
import DealAdvancedStatisticTable from './DealAdvancedStatisticTable.jsx';

import AppContext from '../context/AppContext'
import './DealModal.css'; // Create a separate CSS file for DealModal styling
import { useWeb3React } from '@web3-react/core';

import { getImageUrl } from '../services/backend.js'

/*
<td data-label="Deal"><strong>{deal.deal}</strong></td>
<td data-label="Status" className={`${statusClassName}`}><strong>{deal.status}</strong></td>
<td data-label="User">{telegramId}</td>
<td data-label="Receiver Wallet">{deal.rcvWallet}</td>
<td data-label="Raw Allocation">${deal.rawAlloc}</td>
<td data-label="Net Allocation">${deal.netAlloc} ({deal.fee}%)</td>
<td data-label="Tokens">{deal.totalRcvTkns} / {deal.totalAllocTkns}</td>
<td data-label="Custom Wallet" className={`${customWalletClassName}`}>{deal.customWallet && <span>{deal.customWallet}</span>}</td>*/
const DealStatisticTable = ({ deal }) => {
    const { dealParameters,  dealImages, loggedRole, investors } = useContext(AppContext)

    const { account } = useWeb3React()
    const dealParams = dealParameters[deal.dealId]

    const getInvertorTelegramIdFromAccount = (account) => {
      const lowerAccount = account? account.toLowerCase() : null;
      const telegramId = investors[lowerAccount]? investors[lowerAccount].telegramId : "<unknown>";
      return telegramId
    }

    let telegramId = deal.telegramId?.match('^N\/A') ? 'N/A' : deal.telegramId;
    const rcvWallet = deal.rcvWallet != '0x' ?deal.rcvWallet:account
    telegramId = telegramId?telegramId:getInvertorTelegramIdFromAccount(rcvWallet)

    const URL = getImageUrl(deal.project);

    return (
        <div>
        <table>
          <tbody>
            <tr>
              <td><img src={URL} alt={deal.title} /></td>
              <td><a href={dealParams.detailsUrl?dealParams.detailsUrl:deal.dealId} target="_blank" >{deal.deal}</a></td>
            </tr>
            <tr>
              <td>Status</td>
              <td>{dealParams.status}</td>
            </tr>
            <tr>
              <td>User</td>
              <td>{telegramId?telegramId:"<unknown>"} {loggedRole=="admin"?"✳  ":"  "} <span style={{ color: 'blue' }} className="small-font">{rcvWallet}</span></td>
            </tr>
            <tr>
              <td>Raw Allocation</td>
              <td>{deal.rawAlloc}</td>
            </tr>
             {/* <tr>
              <td>Fee</td>
              <td>{deal.fee}%</td>
    </tr>*/}
            <tr>
              <td>Net Allocation</td>
              <td>{deal.netAlloc}</td>
            </tr>                        
            <tr>
              <td>Total Allocation Tokens</td>
              <td>{deal.totalAllocTkns}</td>
            </tr>
            <tr>
              <td>Total Received Tokens</td>
              <td>{deal.totalRcvTkns}</td>
            </tr>
            
            <tr>
              <td>Token Price</td>
              <td>$ {dealParams?.tokenPrice}</td>
            </tr>

            {deal.customWallet && (
                <tr>
                <td>Custom Wallet</td>
                <td>{deal.customWallet}</td>
              </tr>
            )}
            {/* Add more statistics as needed */}
          </tbody>
        </table>
        {/* loggedRole === "admin" && <DealAdvancedStatisticTable deal={deal}/>*/}

        {dealParams.status === 'FUNDING' && (
          <div>
             <InvestmentForm maxBalance={parseFloat("1000")} deal={deal} dealParameters={dealParams} 
                telegramId={telegramId} loggedRole={loggedRole} />

            {/*<button onClick={() => console.log('Invest')}>{Number(deal.rawAlloc)>0?"Invest More":"Invest"}</button>*/}
          </div>
        )}
      </div>
    );
};

const DealModal = ({ deal, onClose }) => {
    
  const { statistics, loading, error } = useDealsInfo(deal.id); // Assuming each deal has a unique identifier
  
  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };

    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [onClose]);

  return ReactDOM.createPortal(
    <div className="deal-modal-overlay">
    <div className="deal-modal">        
        <div className="modal-header">
          <div className="close-button" onClick={onClose}>❌</div>
        </div>
      <h2>{deal.title}</h2>
      {loading && <p>Loading...</p>}
      {error && <p>Error: {error}</p>}
      {statistics && (
        <DealStatisticTable deal={deal}/>

      )}
      <button style={{textAlign: 'center' }} onClick={onClose}>Close</button>
    </div>
    </div>,
    document.body
  );
};

export default DealModal;