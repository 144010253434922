import { useRef, useState, useMemo, useCallback, useEffect } from "react";
import { searchData, searchDealParameters } from "../services/backend.js";

export function useDealParameters({ deal }) {
  const [dealParameters, setDealParameters] = useState([]);
  const [dealParametersList, setDealParametersList] = useState([]);

  const [loading, setLoading] = useState(false);

  const getDealParameters = useCallback(async ({ deal }) => {
    const dealId = deal ? deal.dealId : null;
    try {
      setLoading(true);
      //const newDealParameters = await searchDealParameters({ search: deal });
      const newDealParameters = await searchData({
        kind: "dealParameters",
        search: dealId,
      });

      setDealParameters(newDealParameters);
    } catch (e) {
      console.error(e.message);
    } finally {
      // tanto en el try como en el catch
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getDealParameters({ deal: deal });
  }, []);

  useEffect(() => {
    let _dealParametersList = [];
    Object.keys(dealParameters).forEach((dealId) => {
      _dealParametersList.push({ dealId: dealId, ...dealParameters[dealId] });
    });
    setDealParametersList(_dealParametersList);
  }, [dealParameters]);

  const addNewDeal = () => {
    setDealParametersList([
      { dealId: "new", deal: "new", status: "new" },
      ...dealParametersList,
    ]);
  };
  return {
    dealParameters,
    dealParametersList,
    getDealParameters,
    addNewDeal,
  };
}
