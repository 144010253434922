import { useRef, useState, useMemo, useCallback, useEffect } from "react";
import { searchData } from "../services/backend.js";

export function useDeals({ account, sort, error }) {
  const [deals, setDeals] = useState([]);
  const [loading, setLoading] = useState(false);
  // el error no se usa pero puedes implementarlo
  // si quieres:
  const [, setError] = useState(null);

  const getDeals = useCallback(async ({ account, error }) => {
    if (error != null) return;
    try {
      setLoading(true);
      setError(null);
      const newDeals = await searchData({
        kind: "deals",
        search: account,
      });
      setDeals(newDeals);
    } catch (e) {
      setError(e.message);
    } finally {
      // tanto en el try como en el catch
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (account) getDeals({ account });
  }, [account]);

  const sortedDeals = useMemo(() => {
    return sort
      ? [...deals].sort((a, b) =>
          a.deal === b.deal
            ? a.telegramId.localeCompare(b.deal)
            : a.deal.localeCompare(b.deal)
        )
      : deals;
  }, [sort, deals]);

  const totalAmount = useMemo(() => {
    if (!deals) return 0;
    let total = 0;
    deals.map((m) => (total += Number(m.rawAlloc)));
    return total;
  }, [sort, deals]);

  const resetDeals = () => {
    setDeals([]);
  };

  return { deals: sortedDeals, getDeals, loading, totalAmount, resetDeals };
}
