import './App.css'
import React from 'react'
import {  BrowserRouter as Router } from "react-router-dom";
import { useDeals } from './hooks/useDeals.js'
import { useRoles } from './hooks/useRoles.js'
import { useDealParameters } from './hooks/useDealParameters.js'
import { useInvestors } from './hooks/useInvestors.js'
import { useGlobalData } from './hooks/useGlobalData.js'
import { useImageManager } from './hooks/useImageManager.js'
import useRouteQuery from "./hooks/useRouteQuery";

import { Deals } from './components/Deals.jsx'
import { InvestorsTable } from './components/Investors.jsx'
import  Carousel  from './components/Carousel.jsx'
import Disclaimer from './components/Disclaimer.jsx'
import DisclaimerModal from './components/DisclaimerModal.jsx';

import { useState, useEffect, useRef, useCallback } from 'react'

import { Web3ReactProvider, useWeb3React } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";

import Header from './components/Header';

import { SWRConfig } from "swr";
import { CSVLink } from 'react-csv';

//import { dealImages } from "./assets/images"

import AppContext from './context/AppContext'

import { ToastContainer, toast} from "react-toastify";
import { helperToast } from './lib/helperToasts.ts'

import DealAdvancedStatisticTable from './components/DealAdvancedStatisticTable.jsx';
import { DealParametersTable } from './components/DealParameters.jsx'

import { CC_PRIVATE_KEY_PARAM } from './config/localStorage';  
import { getLocalStorage, setLocalStorage, checkPrivateKey } from './services/localStorage.js';
import { searchData, searchDealParameters } from "./services/backend.js";

//const allDeals = Object.keys(dealImages)

function getLibrary(provider) {
  const library = new Web3Provider(provider, "any");
  return library;
}

function useSearch () {
  const [search, updateSearch] = useState(undefined)
  const [error, setError] = useState(null)
  const isFirstInput = useRef(true)

  /*
  useEffect(() => {
    if (isFirstInput.current) {
      isFirstInput.current = search === ''
      return
    }

    if (search.match(/^T:.*$/) || search.match(/^D:.*$/)) {
      setError(null)
      return
    }

    if (search === '') {
      setError('No se puede buscar una wallet vacía')
      return
    }

    if (!search.match(/^0x.*$/)) {
      setError('Recuerda que una Wallet EVM empieza por 0x...')
      return
    }
    console.log('search.length', search.length)
    if (search.length != 42) {
      setError('Introduce una wallet EVM correcta (0x...). Total: 42 caracteres')
      return
    }

    setError(null)
  }, [search])*/

  return { search, updateSearch, error }
}



function FullApp () {

  const query = useRouteQuery();
  const { active, account, library } = useWeb3React();
  const { images: dealImages } = useImageManager();
  const [sort, setSort] = useState(false)
  const { globalData } = useGlobalData()
  const { investors, investorsList, getInvestors } = useInvestors()
  const { roles, currentRole, setCurrentRole, loggedRole, switchCurrentRole } = useRoles({account, investors})  
  const [view, setView] = useState('investorView')
  const { search, updateSearch, error } = useSearch()
  const { deals, loading, getDeals, totalAmount, resetDeals } = useDeals({ account : search, sort })  
  const { dealParameters, dealParametersList, getDealParameters, addNewDeal } = useDealParameters({ deal: null })
  const [privateAccess, setPrivateAccess] = useState(false)
  const [showNewInvestorModal, setShowNewInvestorModal] = useState(false);
  const MODE = process.env.REACT_APP_MODE || 'DEV'

  

  useEffect(async () => {
    const cc_priv_key = query.get(CC_PRIVATE_KEY_PARAM)
    if (cc_priv_key) {      
      setLocalStorage(CC_PRIVATE_KEY_PARAM, cc_priv_key)
    }    
    const { check } = await searchData({
      kind: "ckey",
      search: getLocalStorage(CC_PRIVATE_KEY_PARAM),
    });
    setPrivateAccess(check==="OK")
    console.log('check', privateAccess,check==="OK")
  }, [query])

  const CustomToastWithLink = () => (
    <div>
      <a href="/toasttest">This is a link</a>
    </div>
  );


  const getSearch = () => {
    switch (view) {
      case 'adminView':
        return '0x'
      case 'publicPortfolio':
        return '0x'
      case 'investorView':
        return account

      default:
        return search
    }
  }

  useEffect(() => {    
    if (!active) return;
    let _search = getSearch()
    updateSearch(_search)
  }, [account, view])


  const csvData = view==="investors"?[investors]:deals;

  return (
    <AppContext.Provider value={{ 
          MODE, updateSearch,
          currentRole, loggedRole, setCurrentRole,switchCurrentRole, 
          dealImages, dealParameters, getDeals, getDealParameters,
          investors, getInvestors, 
          setView, view, globalData, privateAccess }}>
      <Header loading={loading}  resetDeals={resetDeals} getDeals={getDeals}/>
      <div className='page'>
        {/*
      <iframe
  src="https://docs.google.com/document/d/1ycBh6k9XEvV6adlTC3RhJC0z71ZaNOeftLRrsVg0DAg/edit#heading=h.5z89mv79f19p&embedded=true"
  
  width="90%"
  height="800"
  frameborder="0"
  style={{ border: 'none' }}
  allowfullscreen
  ></iframe>*/}

        {/*(view === 'investorView' || view==="adminView") && <Carousel deals={deals}/>*/}
        {/*<Disclaimer/>*/}
        <header className="app-header">
          {view==="details" && 
              <div><button onClick={()=>{setView('adminView')}} >Back</button></div> }          
          {view==="deals" && 
              <div><button onClick={()=>{addNewDeal()}} >Add New Deal</button></div> }  
          {view==="investors" && 
              <div><button onClick={()=>{setShowNewInvestorModal(true)}} >Add New Investor</button></div> }
          {deals.length>0 &&  
              <CSVLink  data={csvData} filename={'cerberus_club_deals.csv'} className='export-button'  target='_blank'>Export to CSV</CSVLink>}
          {error && <p style={{ color: 'red' }}>{error}</p>}
        </header>
        {currentRole==="details" && false && <div><DealAdvancedStatisticTable deal={deals[0]} /><span>fjk</span></div>}
        <main>        
           {view === 'investors' && <InvestorsTable investors={investorsList} account={account} 
                                                    showNewInvestorModal={showNewInvestorModal}
                                                    setShowNewInvestorModal={setShowNewInvestorModal} />}       
           {(view === 'deals' || view === "publicPortfolio") && <DealParametersTable dealParameters={dealParametersList} account={account}/>}     
           {loading ? <p>Loading...</p> : deals.length>0 && view !== 'investors' &&  view !== 'deals' && view != "publicPortfolio" &&
              <Deals deals={deals} totalAmount={totalAmount} account={account}/>
          }
        </main>
      </div>
      
      <ToastContainer/>
    </AppContext.Provider>
  )
}

function App() {

  return (
    <SWRConfig value={{ refreshInterval: 10000 }}>
      <Web3ReactProvider getLibrary={getLibrary}>
        <Router>
        <div className="app">
          <DisclaimerModal/>
          <FullApp />
        </div>
        </Router>
      </Web3ReactProvider>
    </SWRConfig>
  );
}

export default App;