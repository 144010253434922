import React from "react";
import { useState } from "react";

const CopyToClipboardText = ({ text, onCopied }) => {
  const [copied, setCopied] = useState(false);
  const copyToClipboard = () => {
    // Create a temporary text area element
    const textArea = document.createElement("textarea");
    textArea.value = text;

    // Append the text area to the document
    document.body.appendChild(textArea);

    // Select the text in the text area
    textArea.select();

    // Copy the selected text to the clipboard
    document.execCommand("copy");

    // Remove the temporary text area
    document.body.removeChild(textArea);

    onCopied && onCopied();

    // Provide feedback that text was copied
    setCopied(true);
    // Clear the feedback after a few seconds
    /*
    setTimeout(() => {
      setCopied(false);
      */
  };

  return (
    <span
      className="copyable-text"
      onClick={copyToClipboard}
      style={{ cursor: "pointer" }}
    >
      {text}
      {/*copied && <div className="copy-feedback">Copied to clipboard!</div>*/}
    </span>
  );
};

export default CopyToClipboardText;
