import React, { useState, useEffect } from 'react';
import './DisclaimerModal.css'; // Make sure to update the CSS file name as well

const DisclaimerModal = () => {
  const [isAcknowledged, setIsAcknowledged] = useState(
    localStorage.getItem('disclaimerAcknowledged') === 'true'
  );

  useEffect(() => {
    if (isAcknowledged) {
      //localStorage.setItem('disclaimerAcknowledged', 'true');
    }
  }, [isAcknowledged]);

  /*
  const handleAcknowledge = (e) => {
    setIsAcknowledged(e.target.checked);
  };*/
  const handleAcknowledge = () => {
    setIsAcknowledged(true);
  };

  if (isAcknowledged) return null;

  return (
    <div className="disclaimer-modal-overlay">
      <div className="disclaimer-modal">
        <div className="disclaimer-modal-content">
          
          <h2>CLUB CERBERUS: Consentimiento informado</h2>
          <h3><strong>Antes de invertir</strong>, el usuario declara haber leído y consentido los siguiente puntos:</h3>
          <ol>
            <li>La inversión en criptoactivos no está regulada, puede no ser adecuada para inversores minoristas y perderse la totalidad del importe invertido. Es importante leer y comprender los riesgos de esta inversión que se explican detalladamente a continuación.</li>
            <li>El valor de las inversiones y el rendimiento obtenido de las mismas puede experimentar significativas variaciones al alza y a la baja, pudiendo perderse la totalidad del importe invertido.</li>
            <li>Las inversiones en proyectos en etapas tempranas suponen un alto nivel de riesgo, por lo que resulta necesario entender adecuadamente su modelo de negocio.</li>
            <li>Los criptoactivos del ámbito de esta Circular no están cubiertos por mecanismos de protección al cliente como el Fondo de Garantía de Depósitos o el Fondo de Garantía de Inversores.</li>
            <li>Los precios de los criptoactivos se constituyen en ausencia de mecanismos que aseguren su correcta formación, como los presentes en los mercados regulados de valores.</li>
            <li>Muchos criptoactivos pueden verse carentes de la liquidez necesaria para poder deshacer una inversión sin sufrir pérdidas significativas, dado que su circulación entre inversores, tanto minoristas como profesionales, puede ser muy limitada.</li>
            <li>Las tecnologías de registros distribuidos se encuentran todavía en un estadio temprano de maduración, habiendo sido muchas de estas redes creadas recientemente, por lo que pueden no estar suficientemente probadas y existir fallos significativos en su funcionamiento y seguridad.</li>
            <li>El registro de las transacciones en las redes basadas en tecnologías de registros distribuidos funciona a través de protocolos de consenso que pueden ser susceptibles a ataques que intenten modificar dicho registro y, en caso de tener éxito estos ataques, no existiría un registro alternativo que respalde dichas transacciones ni por tanto a los saldos correspondientes a las claves públicas, pudiéndose perder la totalidad de los criptoactivos.</li>
            <li>Las facilidades de anonimato que pueden aportar los criptoactivos los convierten en un objetivo para los ciberdelincuentes, ya que en el caso de robar credenciales o claves privadas pueden transferir los criptoactivos a direcciones que dificulten o impidan su recuperación.</li>
            <li>La aceptación de los criptoactivos como medio de cambio es aún muy limitada y no existe obligación legal de aceptarlos.</li>
            <li>Los criptoactivos y las claves privadas no están a la disposición de los inversores. Los criptoactivos son almacenados en una cartera fría en nombre de CryptoSagaz. El inversor tiene propiedad económica (puede participar en las ganancias y pérdidas, o comprar, vender como quiera).</li>
            <li>La custodia de los criptoactivos supone una responsabilidad muy relevante ya que pueden perderse en su totalidad en el caso de robo o pérdida </li>

          </ol>
          <div className="checkbox-container">
          <button  onClick={handleAcknowledge} className="acknowledge-btn">He leído y comprendido el Disclaimer. Acepto los términos y condiciones. </button>
          </div>
          {/*
          <div className="checkbox-container">
            <input
              type="checkbox"
              id="acknowledge"
              onChange={handleAcknowledge}
              checked={isAcknowledged}
            />
            <label htmlFor="acknowledge">
              He leído y comprendido el Disclaimer. Acepto los términos y condiciones.
            </label>
  </div>*/}
        </div>
      </div>
    </div>
  );
};

export default DisclaimerModal;
