import { useRef, useState, useMemo, useCallback, useEffect } from "react";
import { searchData } from "../services/backend.js";

export function useRoles({ account, investors }) {
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentRole, setCurrentRole] = useState("user");
  const [loggedRole, setLoggedRole] = useState("user");

  const getRoles = async ({ account }) => {
    try {
      setLoading(true);
      const newRoles = await searchData({ kind: "roles" });
      setRoles(newRoles);
      const newLoggedRole = newRoles[account] ? newRoles[account] : "user";
      setLoggedRole(newLoggedRole);
    } catch (e) {
      console.error(e.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!account) return;
    const lowerAccount = account.toLowerCase();
    const newLoggedRole = investors[lowerAccount]
      ? investors[lowerAccount].role
      : "user";
    setLoggedRole(newLoggedRole);
  }, [account, investors]);

  useEffect(() => {
    if (loggedRole === "user") setCurrentRole("user");
  }, [loggedRole]);

  const getWalletRole = ({ account }) => {
    return roles[account];
  };

  const switchCurrentRole = () => {
    if (currentRole === "user") {
      setCurrentRole("admin");
    } else {
      setCurrentRole("user");
    }
  };

  return {
    roles,
    currentRole,
    setCurrentRole,
    loggedRole,
    switchCurrentRole,
    getRoles,
  };
}
