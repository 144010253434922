
import "./cursors.css";
import "./Investors.css";

import { helperToast } from "../lib/helperToasts.ts";
import AppContext from "../context/AppContext.js";

import InvestorDetailsModal from './InvestorDetailsModal.jsx';

import React, { useState, useEffect, useContext } from 'react';

import { FilterableSortableColumnHeader } from "./Editables.jsx";
import {removeInvestorDetails}  from '../services/backend.js';    

function InvestorRow ({  investor, handleInvestorClick, handleRemoveInvestorClick }) {    
  const { dealImages } = useContext(AppContext)

  return (
    <tr className="investor" key={investor.account}>        
      <td data-label="RemoveImg" className="clickable-element" onClick={() => handleRemoveInvestorClick(investor.account)}>
        <img src={dealImages["remove"]} alt="logo" />
     </td>        
      <td data-label="EditImg" className="clickable-element" onClick={() => handleInvestorClick(investor.account)}>
        <img src={dealImages["edit"]} alt="logo" />
      </td>
      <td data-label="account" >
          {investor.account}
      </td>          
      <td data-label="telegramId">{investor.telegramId}</td>
      <td data-label="discordId">{investor.discordId}</td>
      <td data-label="email">{investor.email}</td>
      <td data-label="role">{investor.role}</td>
      <td data-label="role">{investor.tier}</td>
      <td data-label="role">{Number(investor.tierFee)}</td>
    </tr>
  );
}

function Investors ({ investors, handleInvestorClick, handleRemoveInvestorClick  }) {
  return (
    <>
    {investors.map(investor => {
      return (
        <InvestorRow  investor={investor} handleInvestorClick={handleInvestorClick} handleRemoveInvestorClick={handleRemoveInvestorClick} />
      );
    })}
    </>
  );
}

function ListOfInvestors ({  investors, showNewInvestorModal, setShowNewInvestorModal }) {

  
  const {  getInvestors } = useContext(AppContext)

  const [selectedInvestor, setSelectedInvestor] = useState(null);
  const [sortBy, setSortBy] = useState(''); // Track the currently sorted column
  const [sortOrder, setSortOrder] = useState('asc'); // Track the sorting order
  const [sortKind, setSortKind] = useState('str'); // Track the sorting order
  
  const handleInvestorClick = (account) => {  
      setSelectedInvestor(account);
    };

  const handleCloseModal = () => {
    setSelectedInvestor(null);
  };

  const onError = () => {
    // Implement the logic to handle errors
    console.error('### RemovingInvestorDetails backend failed:');
    helperToast.error(`Removing user details has failed. Please contact with ClubCerberus admins.`)
  };

  const onSuccess = () => { 
    // Implement the logic to update the UI after the transaction has been sent
    //updateSearch(account);
    getInvestors();
    helperToast.success(`User details removed successfully`)

  }

  const handleRemoveInvestorClick = (account) => {
    if (window.confirm(`Are you sure you want to remove ${account}?`)) {
      console.log("Removing investor", account);
      removeInvestorDetails({wallet: account, onError : onError, onSuccess : onSuccess});      
    }
  };

  let sortedInvestors = [...investors];
  const handleSort = (column, kind='str') => {
    
    // Toggle the sorting order if the same column is clicked again
    if (sortBy === column) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      // Set the column to sort by and reset the sorting order
      setSortBy(column);
      setSortKind(kind);
      setSortOrder('asc');
    }
  };
  // Sorting logic based on the column and order
  sortedInvestors.sort((a, b) => {

    if (sortOrder === 'asc') {

      if (sortKind === 'str') {
        let _a = a[sortBy]?a[sortBy]:""
        let _b = b[sortBy]?b[sortBy]:""
        return _a.localeCompare(_b);
      }
      if (sortKind === 'number') return Number(a[sortBy]) > Number(b[sortBy])? 1 : -1;
    } else {
      if (sortKind === 'str') {
        let _a = a[sortBy]?a[sortBy]:""
        let _b = b[sortBy]?b[sortBy]:""
        return _b.localeCompare(_a);
      }
      if (sortKind === 'number') return Number(a[sortBy]) < Number(b[sortBy])? 1 : -1;
    }
  });

  

  const [filters, setFilters] = useState({
    account: '',
    telegramId: '',
    // Add more columns here
  });

  const handleFilterChange = (columnName, e) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [columnName]: e.target.value,
    }));
  };

 const filteredEntries = sortedInvestors.filter((entry) => {
  const { account, telegramId } = entry;
  let _telegramId = telegramId?telegramId:"";
   return (
    entry.account.toLowerCase().includes(filters.account.toLowerCase()) &&
    _telegramId.toLowerCase().includes(filters.telegramId.toLowerCase())
    // Add more conditions for other columns
    );
  });


  return ( 
    <>
    
    <table className='investors'>
      <thead>
        <tr>
          <th>Remove</th>
          <th>Edit</th>
          <th>
            <FilterableSortableColumnHeader colTitle="Account" field="account" handleSort={handleSort} 
                value={filters.account} handleFilterChange={handleFilterChange} />     
          </th>
          <th>
            <FilterableSortableColumnHeader colTitle="Telegram Id" field="telegramId" handleSort={handleSort} 
                value={filters.telegramId} handleFilterChange={handleFilterChange} />     
          </th>
          <th className="sortable-element" onClick={() => {handleSort('discordId')}}>Discord Id</th>
          <th className="sortable-element" onClick={() => {handleSort('email')}}>Email</th>
          <th className="sortable-element" onClick={() => {handleSort('role')}}>Role</th>
          <th className="sortable-element" onClick={() => {handleSort('tier')}}>Tier</th>    
          <th className="sortable-element" onClick={() => {handleSort('tierFee','number')}}>Tier Fee</th>                  
        </tr>
      </thead>      
      <tbody>
        <Investors investors={filteredEntries} handleInvestorClick={handleInvestorClick} handleRemoveInvestorClick={handleRemoveInvestorClick}/>
      </tbody>
    </table>
    {selectedInvestor && (<InvestorDetailsModal account={selectedInvestor} onClose={handleCloseModal}/> )}
    {showNewInvestorModal && (<InvestorDetailsModal account={"0x".toLowerCase()} 
        onClose={()=>{setShowNewInvestorModal(false)}}/> )}

    </>
  )
}
function NoinvestorsResults ({ account }) {
  return (
    account && <p> ❗No investors found  ❗</p> ||
    <p> ❗Please, connect your EVM wallet first ❗</p>
  )
}

export function InvestorsTable ({ investors, account, showNewInvestorModal, setShowNewInvestorModal }) {
  const hasinvestors = Object.keys(investors).length > 0
  return (    
    hasinvestors
      ? <>
        <ListOfInvestors investors={investors} 
              showNewInvestorModal={showNewInvestorModal} setShowNewInvestorModal={setShowNewInvestorModal} />
        </>
      : <NoinvestorsResults  account={account} />
  )
}
