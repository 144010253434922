import { useState, useEffect } from "react";
import { searchData } from "../services/backend.js";

export function useGlobalData() {
  const [globalData, setGlobalData] = useState([]);
  const [loading, setLoading] = useState(false);

  const getGlobalData = async () => {
    try {
      setLoading(true);
      const newGlobalData = await searchData({ kind: "globalData" });
      setGlobalData(newGlobalData);
    } catch (e) {
      console.error(e.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getGlobalData();
  }, []);

  return { globalData, loading };
}
