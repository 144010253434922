// components/InvestmentForm.jsx

import './InvestmentForm.css'; // Create a separate CSS file for InvestmentForm styling

import React, { useState, useEffect, useContext } from 'react';
import { BigNumberish, ethers } from "ethers";

import  useSWR  from "swr";
import { contractFetcher } from "../lib/contracts/contractFetcher.ts";
import { Web3ReactProvider, useWeb3React } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";

import { formatAmount } from "../lib/numbers.ts";
import { tokensInfo, USDT, BUSD, SUPPORTED_CHAINS, getTokenInfo, DEV_CHAINS, getTxUrl, PROD_CHAINS, chainsInfo} from '../config/tokens.js';

import { helperToast } from "../lib/helperToasts.ts";
import { updateInvestment } from "../services/backend.js";

import AppContext from '../context/AppContext'

const CustomToastWithLink = (url, text) => (
  <div>
    <a href={url} target="_blank" >{text}</a>
  </div>
);

const InvestmentForm = ({  deal, dealParameters, telegramId, loggedRole }) => {
  const [investmentAmount, setInvestmentAmount] = useState('');
  const [alternateWallet, setAlternateWallet] = useState('');
  const [isValidAmount, setValidAmount] = useState(false);
  const [isCheckboxChecked, setCheckboxChecked] = useState(false);
  const { active, account, library, chainId, provider } = useWeb3React();
  const [notes, setNotes] = useState('');

  const {  getDeals, investors, globalData, MODE } = useContext(AppContext)
  //const availableTiers = globalData.tiers

  const CustomToastWithLinkToTx = (txHash, text) => (
    <div>
      <span>{text} </span><a href={getTxUrl({chainId : chainId, txHash :  txHash})} target="_blank" > Show TX </a>
    </div>
  );

  const supportedChains = MODE == "PROD" ? PROD_CHAINS : DEV_CHAINS

  const investor = investors[account.toLowerCase()]
  
  console.log("###InvestmentForm investor", investor)
  const [tierName, setTierName ] = useState(investor?.tier ? investor.tier : "General")
  const [tierFee, setTierFee ] = useState(investor?.tierFee ? investor.tierFee : 0)

  const token = getTokenInfo({ chainId : chainId, tokenName: USDT });
  

  const { data: tokenBalance } = useSWR(
    active && supportedChains.includes(chainId) && [`tokenBalance:${active}`, chainId, token?.address , "balanceOf", account],
    {
      fetcher: contractFetcher(library,token?.abi),
    }
  );
  const maxBalance = formatAmount(tokenBalance, 18, 2, false);

  const onError = () => {
    // Implement the logic to handle errors
    console.error('### Investment backend failed:');
    helperToast.error(`Your investment has failed. Please contact with ClubCerberus admins.`)
  };

  const onSuccess = () => { 
    // Implement the logic to update the UI after the transaction has been sent
    console.log('### Investment backend successful');
    //updateSearch(account);
    getDeals({ account });
    helperToast.success(`Your investment has been successfully processed at ClubCerberus. Congratulations.`)
  }

  const onTransactionConfirmed = (transaction) => {
    // Implement the logic to update the UI after the transaction has been confirmed
    console.log('### Transaction confirmed:', transaction);    
    const wallet = alternateWallet ? alternateWallet : account
    console.log("### handleInvest", wallet, fee, notes, ethers.isAddress(wallet))
    const isDelegated = wallet != account;

    updateInvestment({
      dealName: deal.deal,
      wallet: wallet.toLowerCase(),
      /*telegramId: telegramId,*/
      amount: investmentAmount,
      fee: fee,
      tokenPrice: dealParameters.tokenPrice? dealParameters.tokenPrice : 0.3,
      mode: "add",
      txHash : getTxUrl({chainId : chainId, txHash :  transaction.transactionHash}) ,
      notes: notes,
      isDelegated: isDelegated,
      onError : onError,
      onSuccess: onSuccess,
    });
    
  };


  const handleInvest = async (amount) => {
    // Implement the web3 routine to transfer the selected amount to the internal wallet

    console.log(`### Investing ${amount} USDT for deal ${deal.deal}`);
    try {

        const amountInWei = ethers.parseUnits(amount.toString(), 'ether'); // Assuming token has 18 decimal places

        const signer = library.getSigner();
        const contract = new ethers.Contract(token.address, token.abi.abi, signer);

        const transaction = await contract.transfer(dealParameters.raisingWallet.toString(), amountInWei);
        helperToast.info(CustomToastWithLinkToTx(transaction.hash,`Processing your transaction. ${transaction.hash}`))

        library.waitForTransaction(transaction.hash, 1, 150000)
        .then((result) => {
            helperToast.success(CustomToastWithLinkToTx(result.transactionHash,`Transaction Completed. ${result.transactionHash}`))
            onTransactionConfirmed(result);
            setInvestmentAmount('');
            })
        .catch((error) => {
            helperToast.error(error)  
            console.log("###handleInvest error", error)
            })

        // Reset the input field after investing
      } catch (error) {
        console.error('###handleInvest Investment failed:', error.message);
      }

    // You can replace the above console.log with your actual web3 transfer logic
  };

  useEffect(() => {
    // Check if the investment amount is a valid number and within the balance limit
    const isValid = !isNaN(parseFloat(investmentAmount)) && Number(investmentAmount) > 0 && Number(investmentAmount)  <= Number(maxBalance) ;
    setValidAmount(isValid);
  }, [investmentAmount, maxBalance]);

  const handleMaxBalanceClick = () => {
    // Fill the input field with the current balance when clicked
    setInvestmentAmount(maxBalance.toString());
  };

  const handleInvestClick = () => {
    // Call the onInvest function with the selected amount
    handleInvest(parseFloat(investmentAmount));
  };
  console.log("###InvestmentForm investor", investor)
  
  //const tierName = investor?.tier ? investor.tier : "General"
  //const tierFee = investor?.tierFee ? investor.tierFee : 0
  
  const handleTierChange = (event) => {
    
    console.log("@@ handleTierChange", event.target.value)
    const newTier = event.target.value.split(',')[0]
    const newTierFee = event.target.value.split(',')[1]
    setTierName(newTier)
    setTierFee(newTierFee)    
  };

  const fee = tierFee == 100 ? Number(dealParameters.vcFee) : Number(dealParameters.generalFee) - Number(tierFee)
  console.log("###InvestmentForm fee", fee, dealParameters.vcFee, dealParameters.generalFee, tierFee, tierName)

  const isInvestmetDisabled = !isValidAmount || !isCheckboxChecked || !supportedChains.includes(chainId) || !dealParameters?.raisingWallet || 
                              (alternateWallet !== '' && !ethers.isAddress(alternateWallet))

  const getButtonMessage = () => {
    if (!supportedChains.includes(chainId)) return `Please connect to a Supported Chain to Invest: ${getChainNamesList()}}`
    if (!dealParameters?.raisingWallet) return "Raising Wallet hastn't been created yet. Please contact admins"
    if (alternateWallet !== '' && !ethers.isAddress(alternateWallet)) return "Alternate Wallet is invalid"
    if (!isValidAmount) return "Amount is invalid"
    if (!isCheckboxChecked) return "Please read the Disclaimer"
    
    return `INVEST ${DEV_CHAINS.includes(chainId) ? "(TESTNET)" : ""}`
  }

  const getChainNamesList = () => {
    return supportedChains.map((chainId) => chainsInfo[chainId]?.name).join(", ")
  }

  //const tierName = tiers.length>0 ? tiers[0]?.tier : "General"  
  return (
    <>
    {supportedChains.includes(chainId) && dealParameters?.raisingWallet  && (
    <div className="investment-form">
      <div className="balance-info " onClick={handleMaxBalanceClick}>
        <span className="small-font">Current Balance ({token.name}): ${maxBalance}</span>
      </div>
      <input
        type="number"
        id="investmentAmount"
        value={investmentAmount}
        onChange={(e) => setInvestmentAmount(e.target.value)}
        placeholder="Enter amount"
      />  
      {loggedRole == "admin" && (
      <input
        type="text"
        id="alternateWallet"
        value={alternateWallet}
        onChange={(e) => setAlternateWallet(e.target.value)}
        placeholder="Enter alternate wallet (optional)"
        className={ethers.isAddress(alternateWallet) || alternateWallet === '' ? '' : 'invalid-input'}
      />)}
      <input
        type="text"
        id="notes"
        value={notes}
        onChange={(e) => setNotes(e.target.value)}
        placeholder="Enter informative Notes (optional)"
      />          
      <label>
        <input
          type="checkbox"
          checked={isCheckboxChecked}
          onChange={() => setCheckboxChecked(!isCheckboxChecked)}
        />
        I've read the <a href="https://docs.google.com/document/d/1RGnU2Q8f4KoG5bqdbqNW1g697_e4-fCZzGKSHyVjQVU" target="_blank" >Disclaimer</a>
      </label>
      <button disabled={isInvestmetDisabled} onClick={handleInvestClick} >
        {getButtonMessage()}
    </button>     
        {/*
      <div className="balance-info ">
          {loggedRole == "admin" ? (
              <select id="tier" value={[tierName,tierFee]} onChange={handleTierChange}>
              {globalData.tiers.map((tier) => (
                <option key={tier[0]} value={[tier[0],tier[1]]}>
                  {tier[0]}  ({tier[1]}%)
                </option>
              ))}
            </select>
            ) : (
              <div className="small-font">TIER <strong>{tierName}</strong>: <span style={{ color: 'blue' }}> {fee}%</span></div>
            )
          }
        </div>*/}
    </div>)}
     { ! supportedChains.includes(chainId) && (<p style={{ color: 'red' }}> {`Please connect to a Supported Chain to Invest: ${getChainNamesList()}`}</p>)}
     { ! dealParameters?.raisingWallet  && (<p style={{ color: 'red' }}>{"Raising Wallet hastn't been created yet. Please contact admins"}</p>)}
     </>
  );
};

export default InvestmentForm;
