import { useState, useEffect } from "react";

export function useImageManager() {
  const [images, setImages] = useState({});

  useEffect(() => {
    // Initialize images when the component using the hook mounts
    const importImages = async () => {
      const context = require.context("../images", false, /\.(png|jpe?g|svg)$/);
      const importedImages = {};

      context.keys().forEach((item) => {
        const key = item.replace("./", "").replace(".png", "");
        importedImages[key] = context(item);
      });

      setImages(importedImages);
    };

    importImages();
  }, []);

  // Function to handle image upload
  const handleImageUpload = async (file) => {
    try {
      // Upload the file to your server or cloud storage and get a URL
      //const uploadedImageUrl = await uploadImageToServer(file);

      // Create a copy of the current images object
      const newImages = { ...images };

      // Extract the filename from the file
      const fileName = file.name.replace(/\.(png|jpe?g|svg)$/, "");

      // Add the uploaded image URL to the new images object
      //newImages[fileName] = uploadedImageUrl;

      // Update the state with the new images object
      setImages(newImages);

      // Return the updated images object
      return newImages;
    } catch (error) {
      // Handle any upload errors
      console.error("Image upload failed:", error);
      throw error;
    }
  };

  // Function to delete an image by key
  const deleteImage = (key) => {
    const newImages = { ...images };
    delete newImages[key];
    setImages(newImages);
  };

  return {
    images,
    handleImageUpload,
    deleteImage,
  };
}

// Usage example in a component:
// const { images, handleImageUpload, deleteImage } = useImageManager(initialImages);
