// components/ConnectWallet.jsx
import "./cursors.css";
import "./ConnectWallet.css";

import React, { useEffect } from 'react';
import { InjectedConnector } from '@web3-react/injected-connector';
import { useWeb3React } from '@web3-react/core';

import AppContext    from '../context/AppContext';

import { chainsInfo } from '../config/tokens.js';
import InvestorDetailsModal from './InvestorDetailsModal.jsx';
import { useImageManager } from '../hooks/useImageManager.js';
import CopyToClipboardText  from './CopyToClipboardText.js';

import { helperToast } from "../lib/helperToasts.ts";

const injectedConnector = new InjectedConnector();


const ConnectWallet = ({resetDeals, getDeals}) => {
  const { images } = useImageManager();
  const { chainId, account, activate,deactivate, active } = useWeb3React();
  const {investors, loggedRole } = React.useContext(AppContext);
  const [showModal, setShowModal] = React.useState(false);

  const onClick = () => {
    if (active) {
      // If the wallet is active, disconnect it
      deactivate();
      resetDeals();

    } else {
      // If the wallet is not active, connect it
      activate(injectedConnector);
    }
  };

  useEffect(() => {
    getDeals({account : account})
  }
  , [chainId, account, active]);

  const handleCloseModal = () => {
    setShowModal(false);
  };
  useEffect(() => {
    console.log(chainId, account, active);
  }, [chainId, account, active]);

  const lowerAccount = account? account.toLowerCase() : null;
  const telegramId = investors[lowerAccount]? investors[lowerAccount].telegramId : null;
  const userText = telegramId? `(${telegramId})`:" (unknown)";

  const onWalletCoppied = () => {
    helperToast.info("Wallet copied to clipboard", {autoClose : 500}  );
  }

  return (
    <div>
      {active ? (
        <div >✅ <CopyToClipboardText text={account} onCopied={onWalletCoppied}/>
           <img className="clickable-element disconnect-icon" src={images["disconnect"]}  title="Disconnect" alt="Disconnect Account" onClick={onClick}/>
           <br/>
        
           <div className="tooltip-container">
                <span className="clickable-element element-with-tooltip" onClick={() => setShowModal(!showModal) }> 
                   {` ${userText}`}{loggedRole=="admin"?"✳":""} <strong>Chain:</strong> {chainId} ({chainsInfo[chainId]?.name})
                </span>
                <div className="tooltip">
                  <div>
                    <strong>Email:</strong> {investors[lowerAccount]?.email}
                  </div>
                  <div>
                    <strong>Discord ID:</strong> {investors[lowerAccount]?.discord}
                  </div>
                  <div>
                    <strong>Role:</strong> {investors[lowerAccount]?.role}
                  </div>
               </div>
           </div>     

        </div>
      ) : (
        <button type="button" onClick={onClick}>
          Connect Wallet
        </button>
      )}
      {showModal && (<InvestorDetailsModal account={lowerAccount} onClose={handleCloseModal}/> )}
    </div>
  );
};

export default ConnectWallet;
