import React, { useEffect, useContext, useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import ConnectWallet from './ConnectWallet';

import AppContext  from '../context/AppContext'
import { compare } from 'swr/_internal';

import Select from 'react-select';

const Header = ({loading, resetDeals, getDeals}) => {

  const { active } = useWeb3React();
  const { loggedRole, switchCurrentRole, currentRole, setCurrentRole, setView, view, globalData } = useContext(AppContext)
  const [showMenu, setShowMenu] = useState(false);
  
  const getHeaderStyle = () => {
    switch (view) {
      case 'adminView':
        return { color: 'red', fontWeight: 'bold' };
      case 'deals':
          return { color: 'darkred', fontWeight: 'bold' };
      case 'investors':
        return { color: 'darkgreen', fontWeight: 'bold' };
      default:
        return {};
    }
  };
  

  const changeView = (selectedOption) => {
    if (selectedOption) {
      const viewOption = selectedOption.value;
      setView(viewOption);

    }
  };
  const options = [
    { value: 'investorView', label: 'My Portfolio' },
    { value: 'adminView', label: 'View as Admin' },
    { value: 'investors', label: 'Edit Investors' },
    { value: 'deals', label: 'Edit Deals' },
  ];

  const userOptions = [
    { value: 'investorView', label: 'My Portfolio' },
    { value: 'publicPortfolio', label: 'Global Portfolio' },
  ];

  const currentOptions = loggedRole === 'admin' ? options : userOptions;
  const isTest = globalData?.globalParams?globalData.globalParams["MODE"] === "TEST":false;

  return (
    <>
    {isTest && <div style={{color: "red"}} className="test-mode">TEST MODE</div>}
        
    <header className="app-header">
     
      <Select
        value={currentOptions.filter((option) => option.value === view)}
        isDisabled={!active}
        options={currentOptions}
        onChange={changeView}
        placeholder="Select an option"
        isSearchable={false}
        styles={{
          control: (provided) => ({
            ...provided,
            width: '200px', // Adjust the width as needed
            backgroundColor: 'lightblue',
          }),
        }}
      />
   
      <h1 style={getHeaderStyle()}>
        ClubCerberus Portfolio Manager {isTest && "(TEST)"}
      </h1>
      <ConnectWallet resetDeals={resetDeals} getDeals={getDeals}/>
    </header>
    </>
  );
};

export default Header;