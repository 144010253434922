
import "./cursors.css";
import "./DealParameters.css";

import AppContext from "../context/AppContext.js";

import React, { useState, useEffect, useContext, useRef } from 'react';
import { EditableDropdown, EditableInput } from "./Editables.jsx";
import { getObjectWithOnlyChangedFields, changesExist, objectsHaveChanges } from "../lib/objectUtils.js";

import { updateDealParameters, getImageUrl, uploadImageToServer } from "../services/backend.js";

import { helperToast } from "../lib/helperToasts.ts";

function DealParameterRow ({  dealParameter, handleDealParameterClick }) {    
  const { dealImages, globalData, getDealParameters, view, dealParameters } = useContext(AppContext)
  
  const [isEditing, setEditing] = useState(false);
  const [editedDetails, setEditedDetails] = useState(dealParameter);
  const [hasChanges, setHasChanges] = useState(false); // New state to track changes
  
  const [imageUrl, setImageUrl] = useState(getImageUrl(dealParameter.project));
  

  const [file, setFile] = useState(null);
  const fileInputRef = useRef();

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
  };

  const handleUpload = async () => {
    if (file) {
      // Create a new FormData object and append the file
      const formData = new FormData();
      formData.append('image', file);

      // Make an HTTP POST request to your server-side endpoint
      try {
        const response = await fetch('/upload', {
          method: 'POST',
          body: formData,
        });

        if (response.ok) {
          // Handle successful upload
          console.log('File uploaded successfully');
        } else {
          // Handle upload error
          console.error('Upload failed');
        }
      } catch (error) {
        console.error('Error uploading file', error);
      }
    }
  };


  const handleEditClick = () => {
    setEditing(!isEditing);
  }
  const handleInputChange = (param, value) => {
    setEditedDetails((prevDetails) => ({ ...prevDetails, [param]: value }));
  };
  
  const handleDropdownChange = (event) => {
    const { id, value } = event.target;
    setEditedDetails((prevDetails) => ({ ...prevDetails, [id]: value }));
  };

  const onError = () => {
    // Implement the logic to handle errors
    helperToast.error(`Updating Deal Parameters has failed. Please contact with ClubCerberus admins.`)
  };

  const onSuccess = () => { 
    // Implement the logic to update the UI after the transaction has been sent
    //updateSearch(account);
    getDealParameters({});
    helperToast.success(`Deal Parameters updated successfully`)
    setEditing(false);
    setHasChanges(false);
  }

  useEffect(() => { 
    setEditedDetails(dealParameter);
  }, [dealParameter]);

  const handleSaveClick = () => {
    console.log("handleSaveClick", editedDetails, dealParameter)
    const ojectWithOnlyChangedFields = getObjectWithOnlyChangedFields(editedDetails, dealParameter);
    const dataToChange = {
      ...ojectWithOnlyChangedFields, 
      ...(ojectWithOnlyChangedFields.deal && { newName : ojectWithOnlyChangedFields.deal }),
      deal : dealParameter.deal,
      onError : onError, 
      onSuccess : onSuccess
    }
    console.log("dataToChange", dataToChange)
    updateDealParameters(dataToChange);
    return
  }

  useEffect(() => {
    // Check for changes whenever editedDetails or investorDetails change
    const changesExist = objectsHaveChanges(dealParameter, editedDetails)
    setHasChanges(changesExist);
  }, [dealParameter, editedDetails]);

  const savingDisabled = !hasChanges || !isEditing;
  const getSavingStyle = () => {
    const opacity = savingDisabled ? (hasChanges?0.4:0.1) : 1
    return { opacity: opacity }
  }
  const getSavingTitle = () => {
    return savingDisabled ? (hasChanges?"Changes Pending":"No changes") : "Save changes"
  }
  

  const onImageUploadError = () => {
    // Implement the logic to handle errors
    helperToast.error(`Uploading image has failed. Please contact with ClubCerberus admins.`)
  };

  const onImageUploadSuccess = () => {
    // Implement the logic to update the UI after the transaction has been sent
    //updateSearch(account);
    getDealParameters({});
    helperToast.success(`Image uploaded successfully`)
    setImageUrl(getImageUrl(dealParameter.project))
  }
  
  const handleImageFileChange = async (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    const response = await uploadImageToServer({
      file : selectedFile,
      onError : onImageUploadError,
      onSuccess : onImageUploadSuccess});
  };

  const handleImgClick = () => {
    // Programmatically trigger a click event on the hidden file input
    fileInputRef.current.click();
  };

  /*const delInfoUrl =
  {dealParams?.detailsUrl?<a href={dealParams.detailsUrl?dealParams.detailsUrl:deal.dealId} target="_blank" >{deal.deal}</a>:<strong>{deal.deal}</strong>}*/
  const dealParams = dealParameters[dealParameter.dealId]
  return (
    <tr className="dealParameter" key={dealParameter.account}>          
      {view!=="publicPortfolio" && <td data-label="DealParameterImg" className={savingDisabled?"":"clickable-element"}
           disabled={savingDisabled} onClick={() => handleSaveClick()}>
        <img src={dealImages["save"]} alt="logo" style={getSavingStyle()} title={getSavingTitle()}/>
      </td>}  
      {view!=="publicPortfolio" && <td data-label="DealParameterImg" className="clickable-element" onClick={() => handleEditClick()}>
        <img src={dealImages["edit"]} alt="logo" />
      </td>}
      <td data-label="DealImg">            
        <div className={view!=="publicPortfolio"?"clickable-element":""} key={dealParameter.dealId} onClick={view!=="publicPortfolio"?handleImgClick:null}>
           <img src={getImageUrl(dealParameter.project)} alt={""} />          
           <input type="file"  accept="image/*" onChange={handleImageFileChange}  
                ref={fileInputRef} style={{ display: 'none' }}/>             
        </div>
      </td>   
      <td data-label="deal">
        {view!=="publicPortfolio" && <EditableInput originalObject={dealParameter} editedObject={editedDetails} field="deal" onChange={handleInputChange} isEditable={isEditing} />}
        {view==="publicPortfolio" && dealParams?.detailsUrl?<a href={dealParams.detailsUrl?dealParams.detailsUrl:dealParameter.dealId} target="_blank" >{dealParameter.deal}</a>:<strong>{dealParameter.deal}</strong>}
      </td>
      <td data-label="date">{dealParameter.date}</td>
      <td data-label="status">
        <EditableDropdown originalObject={dealParameter} editedObject={editedDetails} field="status" onChange={handleDropdownChange} isEditable={isEditing} options={globalData.status} />
      </td>
      <td data-label="substatus">
        <EditableDropdown originalObject={dealParameter} editedObject={editedDetails} field="substatus" onChange={handleDropdownChange} isEditable={isEditing} options={globalData.substatus} />
      </td>
      <td data-label="vc">
        <EditableDropdown originalObject={dealParameter} editedObject={editedDetails} field="vc" onChange={handleDropdownChange} isEditable={isEditing} options={globalData.vcs} />        
      </td>
      {view!=="publicPortfolio" &&  <td data-label="raisingWallet">
        <EditableInput originalObject={dealParameter} editedObject={editedDetails} field="raisingWallet" onChange={handleInputChange} isEditable={isEditing} />
      </td>}
      {view!=="publicPortfolio" &&  <td data-label="generalFee">
        <EditableInput originalObject={dealParameter} editedObject={editedDetails} field="generalFee" onChange={handleInputChange} isEditable={isEditing} />
      </td>}
      {view!=="publicPortfolio" && <td data-label="vcFee">
        <EditableInput originalObject={dealParameter} editedObject={editedDetails} field="vcFee" onChange={handleInputChange} isEditable={isEditing} />
      </td>}
      <td data-label="round">
        <EditableInput originalObject={dealParameter} editedObject={editedDetails} field="round" onChange={handleInputChange} isEditable={isEditing} /> 
      </td>
      <td data-label="tge">
        <EditableInput originalObject={dealParameter} editedObject={editedDetails} field="tge" onChange={handleInputChange} isEditable={isEditing} /> 
      </td>
      <td data-label="tgeData">
        <EditableInput originalObject={dealParameter} editedObject={editedDetails} field="tgeData" onChange={handleInputChange} isEditable={isEditing} /> 
      </td>
      <td data-label="cliff">
        <EditableInput originalObject={dealParameter} editedObject={editedDetails} field="cliff" onChange={handleInputChange} isEditable={isEditing} />
      </td>    
      <td data-label="vesting">
        <EditableInput originalObject={dealParameter} editedObject={editedDetails} field="vesting" onChange={handleInputChange} isEditable={isEditing} />
      </td>       
      <td data-label="vestingData">
        <EditableInput originalObject={dealParameter} editedObject={editedDetails} field="vestingData" onChange={handleInputChange} isEditable={isEditing} />
      </td>       
    </tr>
  );
}

function DealParameters ({ dealParameters, handleDealParameterClick  }) {
  return (
    <>
    {dealParameters.map(dealParameter => {
      return (
        <DealParameterRow  dealParameter={dealParameter} handleDealParameterClick={handleDealParameterClick} />
      );
    })}
    </>
  );
}

function ListOfDealParameters ({  dealParameters }) {

  const { view } = useContext(AppContext)
  const [selectedDealParameter, setSelectedDealParameter] = useState(null);
  const [sortBy, setSortBy] = useState(''); // Track the currently sorted column
  const [sortOrder, setSortOrder] = useState('asc'); // Track the sorting order
  const [sortKind, setSortKind] = useState('str'); // Track the sorting order
  
  const handleDealParameterClick = (account) => {  
      setSelectedDealParameter(account);
    };

  const handleCloseModal = () => {
    setSelectedDealParameter(null);
  };

  
  let sortedDealParameters = [...dealParameters];
  const handleSort = (column, kind='str') => {
    
    // Toggle the sorting order if the same column is clicked again
    if (sortBy === column) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      // Set the column to sort by and reset the sorting order
      setSortBy(column);
      setSortKind(kind);
      setSortOrder('asc');
    }
  };
  // Sorting logic based on the column and order
  sortedDealParameters.sort((a, b) => {

    if (sortOrder === 'asc') {

      if (sortKind === 'str') {
        let _a = a[sortBy]?a[sortBy]:""
        let _b = b[sortBy]?b[sortBy]:""
        return _a.localeCompare(_b);
      }
      if (sortKind === 'number') return Number(a[sortBy]) > Number(b[sortBy])? 1 : -1;
    } else {
      if (sortKind === 'str') {
        let _a = a[sortBy]?a[sortBy]:""
        let _b = b[sortBy]?b[sortBy]:""
        return _b.localeCompare(_a);
      }
      if (sortKind === 'number') return Number(a[sortBy]) < Number(b[sortBy])? 1 : -1;
    }
  });

  return ( 
    <>
    <table className='dealParameters'>
      <thead>
        <tr>
          {view!=="publicPortfolio" && <th>Save</th>}
          {view!=="publicPortfolio" && <th>Edit</th>}
          <th>Icon</th>
          <th className="sortable-element" onClick={() => {handleSort('deal')}}>Deal Name</th>
          <th className="sortable-element" onClick={() => {handleSort('date')}}>Date</th>
          <th className="sortable-element" onClick={() => {handleSort('status')}}>Status</th>
          <th className="sortable-element" onClick={() => {handleSort('substatus')}}>Substatus</th>
          <th className="sortable-element" onClick={() => {handleSort('vc')}}>VC</th>
          {view!=="publicPortfolio" && <th className="sortable-element" onClick={() => {handleSort('raisingWallet')}}>Raising Wallet</th>}
          {view!=="publicPortfolio" && <th className="sortable-element" onClick={() => {handleSort('generalFee')}}>Gen Fee</th>}
          {view!=="publicPortfolio" && <th className="sortable-element" onClick={() => {handleSort('vcFee','number')}}>VC Fee</th>}
          <th className="sortable-element" onClick={() => {handleSort('round')}}>Round</th>
          <th className="sortable-element" onClick={() => {handleSort('tge','number')}}>TGE (%)</th>
          <th className="sortable-element" onClick={() => {handleSort('tgeData')}}>TGE Data</th>
          <th className="sortable-element" onClick={() => {handleSort('cliff','number')}}>Cliff (m)</th>    
          <th className="sortable-element" onClick={() => {handleSort('vesting','number')}}>Vesting (%)</th>     
          <th className="sortable-element" onClick={() => {handleSort('vestingData')}}>Vesting Data (m)</th>                  
        </tr>
      </thead>      
      <tbody>
        <DealParameters dealParameters={sortedDealParameters} handleDealParameterClick={handleDealParameterClick} />
      </tbody>
    </table>
    {/*selectedDealParameter && (<DealParameterDetailsModal account={selectedDealParameter} onClose={handleCloseModal}/> )*/}
    </>
  )
}
function NodealParametersResults ({ account }) {
  return (
    account && <p> ❗No dealParameters found  ❗</p> ||
    <p> ❗Please, connect your EVM wallet first ❗</p>
  )
}

export function DealParametersTable ({ dealParameters, account }) {

  const hasdealParameters = dealParameters.length > 0
  //console.log("dealParameters", dealParameters,Object.keys(dealParameters).length)
  return (    
    hasdealParameters
      ? <>
        <ListOfDealParameters dealParameters={dealParameters} />
        </>
      : <NodealParametersResults  account={account} />
  )
}
