import { CC_PRIVATE_KEY_PARAM } from "../config/localStorage";

export function getLocalStorage(key) {
  return localStorage.getItem(key);
}

export function setLocalStorage(key, value) {
  localStorage.setItem(key, value);
}

export function removeLocalStorage(key) {
  localStorage.removeItem(key);
}

export function checkPrivateKey() {
  const cc_priv_key = getLocalStorage(CC_PRIVATE_KEY_PARAM);
  console.log(
    cc_priv_key,
    process.env.REACT_APP_CC_PRIV_KEY,
    cc_priv_key === process.env.REACT_APP_CC_PRIV_KEY
  );
  return cc_priv_key === process.env.REACT_APP_CC_PRIV_KEY;
}
