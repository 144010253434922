// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.disclaimer-container {
  align-items: center;
  justify-content: center;
  margin-top: 2px;
  margin-bottom: 2px;
  padding: 5px;
  background-color: #ffdddd;
  border: 1px solid #ff3232;
  border-radius: 5px;
  color: #495057;
  font-size: 14px;
  line-height: 1.5;
  text-align: center;
}
.disclaimer-title {
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 5px;
  color: #ff3232;
}
.disclaimer.show {
  display: block;
}
.disclaimer .hide {
  display: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/Disclaimer.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,uBAAuB;EACvB,eAAe;EACf,kBAAkB;EAClB,YAAY;EACZ,yBAAyB;EACzB,yBAAyB;EACzB,kBAAkB;EAClB,cAAc;EACd,eAAe;EACf,gBAAgB;EAChB,kBAAkB;AACpB;AACA;EACE,eAAe;EACf,gBAAgB;EAChB,kBAAkB;EAClB,cAAc;AAChB;AACA;EACE,cAAc;AAChB;AACA;EACE,aAAa;AACf","sourcesContent":[".disclaimer-container {\n  align-items: center;\n  justify-content: center;\n  margin-top: 2px;\n  margin-bottom: 2px;\n  padding: 5px;\n  background-color: #ffdddd;\n  border: 1px solid #ff3232;\n  border-radius: 5px;\n  color: #495057;\n  font-size: 14px;\n  line-height: 1.5;\n  text-align: center;\n}\n.disclaimer-title {\n  font-size: 28px;\n  font-weight: 600;\n  margin-bottom: 5px;\n  color: #ff3232;\n}\n.disclaimer.show {\n  display: block;\n}\n.disclaimer .hide {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
