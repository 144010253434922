// components/InvestorDetailsModal.jsx
import './InvestorDetailsModal.css';

import React, { useState, useContext, useEffect } from 'react';
import AppContext from '../context/AppContext'

import { BigNumberish, ethers } from "ethers";
import ReactDOM from 'react-dom';

import {updateInvestorDetails}  from '../services/backend.js';    

import { helperToast } from "../lib/helperToasts.ts";
import { EditableDropdown, EditableInput } from "./Editables.jsx";

import { getObjectWithOnlyChangedFields, changesExist, objectsHaveChanges } from "../lib/objectUtils.js";

const InvestorDetailsModal = ({ account, onClose  }) => {
  const isNewInvestor = account === '0x';

  const [isEditing, setEditing] = useState(isNewInvestor);
  
  const { investors, loggedRole, getInvestors, globalData } = useContext(AppContext)
  const investorDetails = investors[account]? {...investors[account],account:account} : {}
  const [editedDetails, setEditedDetails] = useState(investorDetails);
  const [hasChanges, setHasChanges] = useState(false); // New state to track changes

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [onClose]);

  const handleCheckboxChange = () => {
    setEditing(!isEditing);
  };

  const handleInputChange = (param, value) => {
    setEditedDetails((prevDetails) => ({ ...prevDetails, [param]: value }));
  };
  
  const handleDropdownChange = (event) => {
    const { id, value } = event.target;
    setEditedDetails((prevDetails) => ({ ...prevDetails, [id]: value }));
  };

  useEffect(() => {
    setEditedDetails((prevDetails) => ({ ...prevDetails, account: account }));
  }, [account]);

  useEffect(() => {
    // Check for changes whenever editedDetails or investorDetails change

    const changesExist = objectsHaveChanges(investorDetails, editedDetails)
    setHasChanges(changesExist);
  }, [editedDetails, investorDetails]);

  const onError = () => {
    // Implement the logic to handle errors
    console.error('### updateInvestorDetails backend failed:');
    helperToast.error(`Updating user details has failed. Please contact with ClubCerberus admins.`)
  };

  const onSuccess = () => { 
    // Implement the logic to update the UI after the transaction has been sent
    //updateSearch(account);
    getInvestors();
    helperToast.success(`User details updated successfully`)

  }

  const handleSaveChanges = () => {
    // Implement logic to save changes (e.g., make an API call or update state)
    // Reset isEditing state and update investorDetails with editedDetails

    let dataToChange = {}
    if (isNewInvestor) {
      dataToChange = {...editedDetails}
    } else {
      const ojectWithOnlyChangedFields = getObjectWithOnlyChangedFields(editedDetails, investorDetails);
      const wallet = ojectWithOnlyChangedFields?.account ? ojectWithOnlyChangedFields.account : account
      dataToChange = {...ojectWithOnlyChangedFields, 
                            wallet: wallet.toLowerCase(),
                            onError : onError, 
                            onSuccess : onSuccess }
    }
    console.log("### handleSaveChanges.dataToChange", dataToChange)
    
    updateInvestorDetails(dataToChange)
                           
    helperToast.info(`Your user details update are being processed at ClubCerberus.`)

    setEditing(false);
    setEditedDetails({});    
    onClose();

  };

  
  // Set the initial value when investorDetails.tier changes
  useEffect(() => {
    setEditedDetails((prevData) => ({
      ...prevData,
      tier: investorDetails.tier,
      tierFee: Number(investorDetails.tierFee),
    }));
  }, [investorDetails.tier, investorDetails.tierFee]);

  const handleTierChange = (event) => {
    const newTier = event.target.value.split(',')[0]
    const newTierFee = event.target.value.split(',')[1]
    setEditedDetails((prevData) => ({
      ...prevData,
      tier: newTier,
      tierFee:  Number(newTierFee),
    }));
  };

  useEffect(() => {
    if (isNewInvestor) {
      setEditedDetails((prevData) => ({
        ...prevData,
        ['role']: 'user',
        ['tier']: 'GENERAL',
        ['tierFee']: 0,

      }))
    }
  }, [account]);

  return ReactDOM.createPortal(
    <div className="investor-details-modal-overlay">
    <div className="investor-details-modal">        
      <div className="modal-header">    
        <div className="close-button" onClick={onClose}>❌</div>
      </div>
      <h2>Investor Details {investorDetails?.role=="admin"?"✳":""}</h2>
      <table>
        <tbody>
          <tr>
            <td>Account:</td>
            <td>              
            { loggedRole === "admin" &&
              <EditableInput originalObject={investorDetails} editedObject={editedDetails} 
                            field="account" onChange={handleInputChange} isEditable={isEditing}
                            className={ethers.isAddress(editedDetails.account) || editedDetails.account === '' ? '' : 'invalid-input'} />}
            { loggedRole !== "admin" &&
              <div style={{ width: '95%' }}>{editedDetails.account} </div>}

            </td>            
          </tr>
          <tr>
            <td>Telegram ID:</td>
            <td>
              <EditableInput originalObject={investorDetails} editedObject={editedDetails} 
                            field="telegramId" onChange={handleInputChange} isEditable={isEditing} />
            </td>            
          </tr>
          <tr>
            <td>Email:</td>
            <td>
              <EditableInput originalObject={investorDetails} editedObject={editedDetails} 
                            field="email" onChange={handleInputChange} isEditable={isEditing} />
            </td>            
          </tr>
          <tr>
            <td>Discord ID:</td>
            <td>
              <EditableInput originalObject={investorDetails} editedObject={editedDetails} 
                            field="discordId" onChange={handleInputChange} isEditable={isEditing} />
            </td>            
          </tr>
          { loggedRole === "admin" &&
          <tr>
            <td>Role:</td>
            <td>
              <EditableDropdown originalObject={investorDetails} editedObject={editedDetails} 
                   field="role" onChange={handleDropdownChange} isEditable={isEditing} options={globalData.roles} /> 
            </td>            
          </tr>}          
          { loggedRole === "admin" &&
          <tr>
            <td>Tier:</td>
            <td>

              {isEditing ? (
                <select  id="tier" value={[editedDetails.tier,editedDetails.tierFee]} onChange={handleTierChange}>
                {globalData.tiers.map((tier) => (
                  <option key={tier[0]} value={[tier[0],tier[1]]}>
                    {tier[0]}  ({tier[1]}%)
                  </option>
                ))}
              </select>
              ) : (
              <div style={{ width: '95%' }}>{investorDetails.tier} ({investorDetails.tierFee}%) </div>
              )}
            </td>            
          </tr>}
        </tbody>
      </table>
      <div className="edit-checkbox">
        <label>
          Edit Fields
          <input type="checkbox" checked={isEditing} onChange={handleCheckboxChange}/>
        </label>
      </div>
      <div className="save-button">
        <button onClick={handleSaveChanges} disabled={!hasChanges || !isEditing || !ethers.isAddress(editedDetails.account)}>
          Change Investor Data
        </button>
      </div>
    </div>
    </div>,
    document.body
  );
};

export default InvestorDetailsModal;