// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../images/5ire.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-cursor-element {
  cursor: url(${___CSS_LOADER_URL_REPLACEMENT_0___}), auto;
}
.clickable-element {
  cursor: pointer;
}
.sortable-element {
  cursor: ns-resize;
}`, "",{"version":3,"sources":["webpack://./src/components/cursors.css"],"names":[],"mappings":"AAAA;EACE,qDAAuC;AACzC;AACA;EACE,eAAe;AACjB;AACA;EACE,iBAAiB;AACnB","sourcesContent":[".custom-cursor-element {\n  cursor: url('../images/5ire.png'), auto;\n}\n.clickable-element {\n  cursor: pointer;\n}\n.sortable-element {\n  cursor: ns-resize;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
