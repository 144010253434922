export const getObjectWithOnlyChangedFields = (objectX, objectY) => {
  const objectWithOnlyChangedFields = {};
  Object.keys(objectX).forEach((key) => {
    const editedValue = objectX[key] || ""; // Use an empty string if value is null
    const originalValue = objectY[key] || ""; // Use an empty string if value is null
    if (editedValue !== originalValue) {
      objectWithOnlyChangedFields[key] = editedValue;
    }
  });
  return objectWithOnlyChangedFields;
};

export const getObjectWithOnlyChangedFieldsReduce = (objectX, objectY) => {
  return Object.keys(objectX).reduce((acc, key) => {
    const editedValue = objectX[key] || "";
    const originalValue = objectY[key] || "";
    if (editedValue !== originalValue) {
      acc[key] = editedValue;
    }
    return acc;
  }, {});
};

export const objectsHaveChanges = (objectX, objectY) => {
  const hasChanges = Object.keys(objectY).some((key) => {
    const editedValue = objectY[key] || ""; // Use an empty string if value is null
    const originalValue = objectX[key] || ""; // Use an empty string if value is null
    return editedValue !== originalValue;
  });
  return hasChanges;
};
