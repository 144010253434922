
import "./cursors.css";
import "./Deals.css";

import React, { useState, useEffect, useContext } from 'react';

import DealModal from './DealModal';
import { CSVLink } from 'react-csv';

import AppContext  from '../context/AppContext'
import { getImageUrl } from '../services/backend.js'
import { FilterableSortableColumnHeader } from "./Editables.jsx";

import { checkPrivateKey } from "../services/localStorage.js";

const getStatusClassNames = (status) => {
  switch (status) {
    case 'FUNDED':
      return 'funded'
    case 'DISTRIBUTED':
      return 'distributed'
    case 'COLLECTING WALLET':
      return 'cWallet'
    case 'DISTRIBUTING':
        return 'distributing'
    case 'FUNDING':
        return 'funding'
    case 'PREPARING':
        return 'preparing'
    default:
      return ''
  }
}

const TxList = ({ deal }) => {
  return (<>
   {deal.txs && deal.txs.length > 0 ? (
    <ul style={{padding: 0, margin: 0 }}>
      {deal.txs.map((tx, index) => { 
        if (tx===null) return null
        const date = tx[3]
        const time = tx[4]
        let tooltip = deal.notes && deal.notes[index] ? deal.notes[index] : ""
        tooltip = date != ""? `${date} ${time}` + "\n" + tooltip  : tooltip
        return (
        <li key={index}>
          <a href={tx[2]} target="_blank" rel="noopener noreferrer"
             title={tooltip}>
            ${tx[0]} 
          </a>
        </li>
      )})}
    </ul>
  ) : ('No events')}
  </>)
}

const getCustomWalletClassName = (value)  => {
  if (value === 'NOT_FOUND')  return 'not_found'
  return ""
}
function DealRow ({ deal, handleDealClick }) {
  const { currentRole, dealImages, dealParameters, view,  investors, privateAccess } = useContext(AppContext)
  const dealParams = dealParameters[deal.dealId]
  //const telegramId = deal.telegramId?.match('^N\/A') ? 'N/A' : deal.telegramId;
  const statusClassName = getStatusClassNames(dealParams?.status);
  const customWalletClassName = getCustomWalletClassName(deal.customWallet)


  const status = dealParams?.status?dealParams.status:"UNKNOWN"
  const substatus = dealParams?.substatus==="NONE"?"":`/${dealParams?.substatus}`
  
  const kind = dealParams?.kind?dealParams.kind:"PUBLIC"
  if ((status==="FUNDING" || status==="PREPARING") && kind==="PRIVATE" && !privateAccess) return null

  if (view!=="adminView" && status==="PREPARING") return null
  
  const toCV = dealParams?.vcFee? (deal.netAlloc / (100 - dealParams.vcFee))*100.0 : 0.0
  const fee = (deal.rawAlloc - deal.netAlloc)/deal.rawAlloc*100.0
  const netFee = toCV > 0.0 ? (deal.rawAlloc - toCV) : 0.0
  //URL=`http://localhost:5000/img/${deal.project}.png`
  const URL = getImageUrl(deal.project);
  
  const investor = deal.rcvWallet? investors[deal.rcvWallet.toLowerCase()]:{}
  const telegramId = investor?.telegramId ? investor.telegramId : "N/A"
  
  return (
    <tr className="deal" key={deal.id}>   

      <td data-label="DealImg">            
        <div className="clickable-element" key={deal.id} onClick={() => handleDealClick(deal)}>
           <img src={URL} alt={deal.title} />
        </div>
      </td>          
      <td data-label="Deal">
      {dealParams?.detailsUrl?<a href={dealParams.detailsUrl?dealParams.detailsUrl:deal.dealId} target="_blank" >{deal.deal}</a>:<strong>{deal.deal}</strong>}
      </td>
      
      <td data-label="Status" className={`${statusClassName}`}><strong>{status}{substatus}</strong></td>
      {view==="details" && <td data-label="User">{telegramId}</td>}
      {view==="details" && <td data-label="Receiver Wallet">{deal.rcvWallet}</td>}      
      {view==="adminView" && <td data-label="Receiver Wallet">{deal.investors}</td>}
      <td data-label="Allocation">${deal.rawAlloc}</td>
      {/*<td data-label="Net Allocation">${deal.netAlloc} ({fee.toFixed(2)}%)</td>      */}
      {/*view==="adminView" && <td data-label="To Vc">${toCV.toFixed(2)}</td>*/}
      {/*view==="adminView" && <td data-label="Net Fee">${netFee.toFixed(2)}</td>*/}
      <td data-label="Token Price">{dealParams?.tokenPrice}</td>
      <td data-label="Tokens">{deal.totalRcvTkns} / {deal.totalAllocTkns}</td>      
      {view!=="adminView" && <td data-label="dealEvents"> <TxList deal={deal} /> </td>}
      {view!=="adminView" && <td data-label="Custom Wallet" className={`custom-wallet ${customWalletClassName}`}>{deal.customWallet && <span>{deal.customWallet}</span>}</td>}
      {view==="adminView" && <td data-label= "Funding Wallet">{dealParams?.raisingWallet}</td>}
      {/*view==="adminView" && <td data-label= "Fees">{dealParams?.generalFee}%/{dealParams?.vcFee}%</td>*/}          
      {view!=="details" && <td data-label= "Round">{dealParams?.round}</td>}        
      {view!=="details" && <td  className="tge-data" data-label= "TGE Data">{dealParams?.tge?`${dealParams.tge}%`:""} {dealParams?.tgeData?`- ${dealParams.tgeData}`:""}</td>}  
      {view!=="details" && <td className="cliff-data" data-label= "CLIFF Data (m)">{dealParams?.cliff?`${dealParams.cliff}m`:""} {dealParams?.cliffData?`- ${dealParams.cliffData}`:""}</td>}
      {view!=="details" && <td className="vesting-data" data-label= "VESTING Data (%)">{dealParams?.vesting?`${dealParams.vesting}%`:""} {dealParams?.vestingData?`- ${dealParams.vestingData}`:""}</td>}
    </tr>
  );
}
function FundingDeals ({ deals, handleDealClick , totalSetters }) {
    
  const { dealParameters } = useContext(AppContext);
  return (
    <>
    {deals.map(deal => {
      const dealParams = dealParameters[deal.dealId]
      if (dealParams?.status !== 'FUNDING') return;
      return (
        <DealRow deal={deal} handleDealClick={handleDealClick}/>
      );
    })}
    </>
  );
}

function PreparingDeals ({ deals, handleDealClick, totalSetters  }) {
  
  const { dealParameters } = useContext(AppContext);
  return (
    <>
    {deals.map(deal => {
      const dealParams = dealParameters[deal.dealId]
      if (dealParams?.status !== 'PREPARING') return;
      return (
        <DealRow deal={deal} handleDealClick={handleDealClick}/>
      );
    })}
    </>
  );
}


function FundedDeals ({ deals, handleDealClick }) {
  
  const { dealParameters } = useContext(AppContext);
  return (
    <>
    {deals.map(deal => {
      const dealParams = dealParameters[deal.dealId]
      if (dealParams?.status === 'FUNDING' || dealParams?.status === 'PREPARING' ) return;
      return (
        <DealRow deal={deal} handleDealClick={handleDealClick} />
      );
    })}
    </>
  );
}


function ListOfDeals ({  deals, totalAmount}) {

  const [selectedDeal, setSelectedDeal] = useState(null);
  const [sortBy, setSortBy] = useState(''); // Track the currently sorted column
  const [sortOrder, setSortOrder] = useState('asc'); // Track the sorting order
  const [sortKind, setSortKind] = useState('str'); // Track the sorting order
  const [dealFilter, setDealFilter] = useState('');

  const { view, dealParameters, updateSearch, setCurrentRole, setView } = useContext(AppContext)

  const handleDealClick = (deal) => {
    if (view === 'adminView') {
      const dealSearch = `D:${deal.deal}`
      updateSearch(dealSearch)
      setView('details')
    } else {  
      setSelectedDeal(deal);
    }
  };

  const handleCloseModal = () => {
    setSelectedDeal(null);
  };


  const getColumnSum = (columnName) => {
    // Calculate the sum of values in the specified column
    return deals.reduce((sum, deal) => sum + parseFloat(deal[columnName] || 0), 0).toFixed(2);
  };

  let sortedDeals = [...deals];

  const handleSort = (column, kind='str') => {
    // Toggle the sorting order if the same column is clicked again
    if (sortBy === column) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      // Set the column to sort by and reset the sorting order
      setSortBy(column);
      setSortKind(kind);
      setSortOrder('asc');
    }
  };
  
  // If deals change, then this useEffect would go through all of then adding status and substatus
  useEffect(() => {
    sortedDeals.forEach((deal) => {
      const dealParams = dealParameters[deal.dealId]
      if (dealParams) {
        deal.status = dealParams.status
        deal.substatus = dealParams.substatus
      }
    })
  }, [deals, dealParameters])

  // Sorting logic based on the column and order
  sortedDeals.sort((a, b) => {    
    if (sortOrder === 'asc') {
      
      if (sortKind === 'str') {
        let _a = a[sortBy]?a[sortBy]:""
        let _b = b[sortBy]?b[sortBy]:""
        return _a.localeCompare(_b);
      }
      if (sortKind === 'number') return Number(a[sortBy]) > Number(b[sortBy])? 1 : -1;
    } else {
      if (sortKind === 'str') {
        let _a = a[sortBy]?a[sortBy]:""
        let _b = b[sortBy]?b[sortBy]:""
        return _b.localeCompare(_a);
      }
      if (sortKind === 'number') return Number(a[sortBy]) < Number(b[sortBy])? 1 : -1;
    }
  });

  // Handle input change for the "Deal" filter
  const handleDealFilterChange = (event) => {
    setDealFilter(event.target.value);
  };

  // Filter deals based on the dealFilter value
  const filteredDeals = sortedDeals.filter((deal) =>
    deal.deal.toLowerCase().includes(dealFilter.toLowerCase())
  );

  const [filters, setFilters] = useState({
    deal: '',
    status: '',
    // Add more columns here
  });

  const handleFilterChange = (columnName, e) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [columnName]: e.target.value,
    }));
  };

 const filteredEntries = sortedDeals.filter((entry) => {
  const { deal, status } = entry;
  let _status = status?status:""; 
  return (
    entry.deal.toLowerCase().includes(filters.deal.toLowerCase()) &&
    _status.toLowerCase().includes(filters.status.toLowerCase())
    // Add more conditions for other columns
    );
  });


  return ( 
    <>
    
    {/*<div className="sticky-header">*/}
    <table className='deals'>
      <thead>
        <tr>
          <th>Icon</th>
          <th>
            <FilterableSortableColumnHeader colTitle="Deal" field="deal" handleSort={handleSort} 
                value={filters.deal} handleFilterChange={handleFilterChange} />            
          </th>
          <th>
            <FilterableSortableColumnHeader colTitle="Status" field="status" handleSort={handleSort} 
                value={filters.status} handleFilterChange={handleFilterChange} />
          </th>
          {view==="details" && <th  className="sortable-element" onClick={() => handleSort('telegramId')}>User</th>}
          {view==="details" && <th>User Wallet</th>}
          {view==="adminView" && <th>Investors</th>}
          <th className="sortable-element" onClick={() => handleSort('rawAlloc','number')}>Allocation</th>
          {/*<th className="sortable-element" onClick={() => handleSort('netAlloc','number')}>Net Alloc<br/>${getColumnSum('netAlloc')} </th>*/}
          {/*view==="adminView" && <th>To VC</th>*/}
          {/*view==="adminView" && <th>Net Fee</th>*/}          
          <th>Token Price</th>
          <th className="" /*onClick={() => handleSort('totalAllocTkns','number')}*/>Tokens</th>
          {view!=="adminView" && <th>Deal Events</th>}
          {view!=="adminView" && <th className="custom-wallet">Custom Wallet</th>}
          {view==="adminView" && <th>Funding Wallet</th>}
          {/*view==="adminView" && <th>Fees</th>*/}          
          {view!=="details" && <th>Round</th>}   
          {view!=="details" && <th className="tge-data">TGE Data</th>}       
          {view!=="details" && <th className="cliff-data">CLIFF Data (m)</th>}
          {view!=="details" && <th className="vesting-data">VESTING Data (%)</th>}
        </tr>
      </thead>      
      {/* </table>
   </div>
    <table className='deals'>*/}
      <tbody>
        <FundingDeals deals={filteredEntries} handleDealClick={handleDealClick}/>
        <PreparingDeals deals={filteredEntries} handleDealClick={handleDealClick}/>
        <FundedDeals deals={filteredEntries} handleDealClick={handleDealClick}  />
      </tbody>
    </table>
    {selectedDeal && (<DealModal deal={selectedDeal} onClose={handleCloseModal} /> )}
    </>
  )
}
function NoDealsResults ({ account }) {
  return (
    account && <p> ❗No Deals found for {account} ❗</p> ||
    <p> ❗Please, connect your EVM wallet first ❗</p>
  )
}

export function Deals ({ deals, totalAmount, account }) {
  const hasDeals = deals?.length > 0
  return (
    
    hasDeals
      ? <>
        {false && <CSVLink  data={deals} filename={'cerberus_club_deals.csv'} className='export-button'  target='_blank'>Export to CSV</CSVLink>}
        <ListOfDeals deals={deals} totalAmount={totalAmount} />
        </>
      : <NoDealsResults  account={account} />
  )
}
