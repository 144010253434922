import React, { useContext } from 'react'
import AppContext from '../context/AppContext'

import './DealModal.css'; // Create a separate CSS file for DealModal styling

function DealAdvancedStatisticTable ({ deal }) {    
    const { dealParameters } = useContext(AppContext)
    const dealParams = dealParameters[deal.dealId]
  
    return (        <table className='deals'>
      <tbody>
        <tr>
          <td>Funding Wallet</td>
          <td>{dealParams?.raisingWallet}</td>
        </tr>
        <tr>
          <td>General Fee</td>
          <td>{dealParams?.generalFee}%</td>
        </tr>
        <tr>
          <td>VC Fee ({dealParams?.vc})</td>
          <td>{dealParams?.vcFee}%</td>
        </tr>
        <tr>
          <td>Round</td>
          <td>{dealParams?.round}</td>
        </tr>
        <tr>
          <td>TGE Data</td>
          <td> {dealParams?.tge?`${dealParams.tge}%`:""} {dealParams?.tgeData?`- ${dealParams.tgeData}`:""}</td>
        </tr>
        <tr>
          <td>CLIFF Data (m)</td>
          <td> {dealParams?.cliff?`${dealParams.cliff}m`:""} {dealParams?.cliffData?`- ${dealParams.cliffData}`:""}</td>
        </tr>
        <tr>
          <td>VESTING Data (%)</td>
          <td> {dealParams?.vesting?`${dealParams.vesting}%`:""} {dealParams?.vestingData?`- ${dealParams.vestingData}`:""}</td>
        </tr>
  
        {/* Add more statistics as needed */}
      </tbody>
    </table>)
  }

  export default DealAdvancedStatisticTable;