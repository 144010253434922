// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sticky-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 90%;
  background-color: white;
  z-index: 100;
  display: none;
}

.sticky-header.visible {
  display: block;
}
`, "",{"version":3,"sources":["webpack://./src/components/Deals.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,UAAU;EACV,uBAAuB;EACvB,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".sticky-header {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 90%;\n  background-color: white;\n  z-index: 100;\n  display: none;\n}\n\n.sticky-header.visible {\n  display: block;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
