import React, { useState, useEffect } from 'react';
import './Disclaimer.css'; // Create a separate CSS file for Disclaimer styling

// show a disclaimer message with a arrow or a button to collapse the message   
const Disclaimer = () => {
 

  return (
      <div className='disclaimer-container'>
        <h1 className='disclaimer-title'>Disclaimer</h1> 
        <p>{"Investing in private placements requires high risk tolerance, low liquidity need, and long-term commitments.  Investors must be able to afford to lose their entire investment. See the full disclaimer "}
           <a href="https://docs.google.com/document/d/1RGnU2Q8f4KoG5bqdbqNW1g697_e4-fCZzGKSHyVjQVU" target="_blank" >Español</a> 
           {" or "}<a href="https://docs.google.com/document/d/1helCi2SE3i4bUZYLDDVlFapHxpwb8D8Skmm7XeXtBCM" target="_blank" >English</a>.</p>
       </div>

  );
};

export default Disclaimer;
